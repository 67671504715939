var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// apis/azure/azureGeoApi.ts
import axios from "axios";
var AzureGeoApi = class {
  static API_KEY = process.env.AZURE_MAPS_API_KEY;
  // See https://learn.microsoft.com/en-us/rest/api/maps/geolocation/get-ip-to-location?view=rest-maps-1.0&tabs=HTTP
  static async getCountry(ip) {
    const countryReq = await axios.get(
      `https://atlas.microsoft.com/geolocation/ip/json?api-version=1.0&ip=${ip}&subscription-key=${this.API_KEY}`
    );
    return countryReq.data;
  }
};

// apis/braze/braze.service.ts
import axios2 from "axios";
var Braze = class _Braze {
  static API_KEY = process.env.BRAZE_API_KEY;
  static APP_URL = process.env.BRAZE_INSTANCE_URL;
  // See https://www.braze.com/docs/api/objects_filters/messaging/apple_object/
  // See https://www.braze.com/docs/api/endpoints/messaging/send_messages/post_send_messages#request-parameters
  static async sendTestPushNotification(externalUserIds) {
    return axios2({
      method: "post",
      url: `${_Braze.APP_URL}/messages/send`,
      headers: {
        Authorization: `Bearer ${_Braze.API_KEY}`,
        "Content-Type": "application/json"
      },
      data: {
        messages: {
          apple_push: {
            alert: `Test push notification from ${process.env.NODE_ENV} Curaleaf Graph API.`
          }
        },
        external_user_ids: externalUserIds
      }
    });
  }
};

// apis/form/braze.ts
import axios3 from "axios";
import axiosRetry from "axios-retry";
import moment from "moment";
var BrazeApi = class {
  constructor(instanceUrl, apiKey, appKey) {
    this.instanceUrl = instanceUrl;
    this.apiKey = apiKey;
    this.appKey = appKey;
    if (!instanceUrl || !instanceUrl.length || !apiKey || !apiKey.length || !appKey || !appKey.length)
      throw "Braze .env variables are missing!";
    const tempAxios = axios3.create({
      baseURL: this.instanceUrl,
      headers: { Authorization: `Bearer ${this.apiKey}` },
      validateStatus: () => true
    });
    axiosRetry(tempAxios, {
      retries: 3,
      retryCondition: () => true,
      retryDelay: (retryCount) => {
        return retryCount * 2e3;
      }
    });
    this.braze = tempAxios;
  }
  braze;
  async submitIntakeForm(formData, customerIsCbd, eventName) {
    if (formData.optIn) {
      const userAlias = {
        alias_name: formData.email.toLowerCase().trim(),
        alias_label: "email"
      };
      const optInMethod = [];
      if (formData.preferredMethodOfCommunication) {
        if (formData.preferredMethodOfCommunication.email)
          optInMethod.push("Email");
        if (formData.preferredMethodOfCommunication.phone)
          optInMethod.push("Phone");
      }
      const attributes = {
        user_alias: userAlias,
        _update_existing_only: false,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        state: formData.state,
        phone: formData.phone,
        zipCode: formData.zipCode,
        optInMethod: void 0,
        prefCommMethod: optInMethod.sort().join(","),
        email_subscribe: formData.preferredMethodOfCommunication && formData.preferredMethodOfCommunication.email ? "opted_in" : void 0,
        medOrRec: formData.customerType === "Medical" ? "Med" : formData.customerType === "Recreational" ? "Rec" : formData.cardStatus,
        interested: formData.cardStatus,
        cbd_customer: customerIsCbd,
        howDidYouHearAboutUs: formData.hdyhau,
        dispensary: formData.dispensaryPreference,
        dob: formData.dob ? moment(formData.dob).format("YYYY-MM-DD") : void 0,
        birthMonth: formData.birthMonth
      };
      const events = [];
      const nowMoment = moment();
      const date = nowMoment.utc().format();
      events.push({
        user_alias: userAlias,
        app_id: this.appKey,
        name: eventName || "online-customer-intake",
        time: date
      });
      const trackReq = await this.userTrack([attributes], events, []);
      return {
        success: trackReq,
        message: trackReq ? "" : "Failed to create customer"
      };
    } else {
      return {
        success: false,
        message: "Please make sure you are opt-ing in."
      };
    }
  }
  async queryAliases(aliases) {
    const reqBody = {
      user_aliases: aliases
    };
    const axiosRequest = await this.braze.post("/users/export/ids", reqBody);
    return {
      success: axiosRequest.status === 201,
      data: axiosRequest.data
    };
  }
  async userTrack(attributes, events, purchases) {
    const reqBody = {
      attributes,
      events,
      purchases
    };
    const axiosRequest = await this.braze.post("/users/track", reqBody);
    return axiosRequest.status === 201;
  }
  async lookupCustomer(email) {
    const alias = {
      alias_label: "email",
      alias_name: email.toLowerCase()
    };
    const queriedUser = await this.queryAliases([alias]);
    const userMatch = queriedUser.data.users.find(
      (user) => user.email && user.email.toLowerCase() === email.toLowerCase()
    );
    return {
      success: !!userMatch,
      userData: userMatch
    };
  }
  async lookupCustomerByExternalId(id, email) {
    const reqBody = {
      external_ids: [id]
    };
    const axiosRequest = await this.braze.post("/users/export/ids", reqBody);
    const userMatch = axiosRequest.data.users.find(
      (user) => user.email && user.email.toLowerCase() === email.toLowerCase()
    );
    return {
      success: !!userMatch,
      userData: userMatch
    };
  }
  async editUser(formData) {
    const mappedForm = {
      external_id: formData.curaleaf_account_rid,
      _update_existing_only: false,
      first_name: formData.firstName,
      last_name: formData.lastName,
      ...formData
    };
    delete mappedForm.firstName;
    delete mappedForm.lastName;
    delete mappedForm.curaleaf_account_rid;
    Object.keys(mappedForm).map((key) => {
      const value = mappedForm[key];
      if (!value || typeof value === "string" && value === "") {
        delete mappedForm[key];
      }
    });
    const trackUser = await this.userTrack(
      [
        {
          external_id: formData.curaleaf_account_rid,
          _update_existing_only: false,
          ...mappedForm,
          prefCommMethod: mappedForm.prefCommMethod ? mappedForm.prefCommMethod.join(",") : void 0
        }
      ],
      [
        {
          external_id: formData.curaleaf_account_rid,
          app_id: this.appKey,
          name: "profileEdit",
          time: moment().toISOString()
        }
      ],
      []
    );
    return {
      success: trackUser,
      message: trackUser ? "" : "Failed To Track User"
    };
  }
  async checkHipaaStatus(email) {
    const exisitngRecords = await this.queryAliases([
      { alias_label: "email", alias_name: email.toLowerCase().trim() }
    ]);
    if (!exisitngRecords.success) {
      return { success: false, data: void 0 };
    }
    const match = exisitngRecords.data.users.find(
      (u) => u.email.toLowerCase().trim() === email.toLowerCase().trim()
    );
    if (!match) {
      return {
        success: false,
        data: void 0
      };
    }
    const hipaaSignedEvent = match.custom_events && match.custom_events.length && match.custom_events.find((event) => event.name === "hipaaFormSubmitted");
    return {
      success: true,
      data: {
        hipaaAcknowledged: match.custom_attributes.hipaaAcknowledged,
        hipaaSubmitted: !!hipaaSignedEvent,
        hipaaSubmittedDate: hipaaSignedEvent ? hipaaSignedEvent.last : void 0
      }
    };
  }
  async submitHipaaForm(formData) {
    const userAlias = {
      alias_name: formData.patient.email.toLowerCase().trim(),
      alias_label: "email"
    };
    const authorizedAgent = `${formData.agent.firstName} ${formData.agent.lastName}`.trim();
    const attributes = {
      user_alias: userAlias,
      _update_existing_only: false,
      hipaaFormData: JSON.stringify({
        acknowledge: formData.acknowledge,
        patient_email: `${formData.patient.email}`,
        patient_name: `${formData.patient.firstName} ${formData.patient.lastName}`,
        patient_relationship: formData.patientRelationship,
        agent_name: authorizedAgent,
        gaveSignature: formData.signature,
        date: formData.date,
        didNotAcknowledgeReason: formData.didNotAcknowledgeReason
      }),
      hipaaAcknowledged: !!formData.signature,
      authorizedAgent: authorizedAgent.length ? authorizedAgent : void 0,
      first_name: formData.patient.firstName,
      last_name: formData.patient.lastName,
      email: formData.patient.email
    };
    const events = [];
    events.push({
      user_alias: userAlias,
      app_id: this.appKey,
      name: "hipaaFormSubmitted",
      time: formData.date
    });
    const existingRecords = await this.queryAliases([userAlias]);
    if (!existingRecords.success)
      return {
        success: false,
        message: "Error querying existing records."
      };
    const matchingRecord = existingRecords.data.users.find(
      (rec) => rec.email === formData.patient.email
    );
    if (!matchingRecord) attributes.email_subscribe = "unsubscribed";
    const trackReq = await this.userTrack([attributes], events, []);
    return {
      success: trackReq,
      message: trackReq ? "" : "Failed to create customer"
    };
  }
  async sendCampaignEmail(email, externalId, campaignId, extraProperties) {
    const sendReq = await this.braze.post("/campaigns/trigger/send", {
      campaign_id: campaignId,
      recipients: [
        {
          external_user_id: externalId,
          trigger_properties: extraProperties,
          send_to_existing_only: false,
          attributes: {
            email
          }
        }
      ]
    });
    return sendReq.status === 201;
  }
  async sendCanvasMessage(email, externalId, canvasId, extraProperties) {
    const sendReq = await this.braze.post("/canvas/trigger/send", {
      canvas_id: canvasId,
      recipients: [
        {
          external_user_id: externalId,
          canvas_entry_properties: extraProperties,
          send_to_existing_only: false,
          attributes: {
            email
          }
        }
      ]
    });
    return sendReq.status === 201;
  }
  async sendCanvasMessages(canvasId, messages) {
    const sendReq = await this.braze.post("/canvas/trigger/send", {
      canvas_id: canvasId,
      recipients: messages.map((msg) => ({
        email: msg.email,
        external_user_id: msg.externalId,
        canvas_entry_properties: msg.extraProperties
      }))
    });
    return sendReq.status === 201;
  }
  async changeSubscriptionStatus(email, subscribe, emailVerificationStep) {
    let reqBody = {};
    if (emailVerificationStep) {
      reqBody = {
        email,
        subscription_state: subscribe ? "opted_in" : "subscribed"
      };
    } else {
      reqBody = {
        email,
        subscription_state: subscribe == "opted_in" ? "opted_in" : "unsubscribed"
      };
    }
    const axiosRequest = await this.braze.post("/email/status", reqBody).catch((err) => console.error(err));
    return axiosRequest.status === 201;
  }
};

// apis/coa/getCoa.ts
import axios4 from "axios";
async function GetCOA(batchId) {
  if (!process.env.COAS_URL) {
    return null;
  }
  const coaData = await axios4(
    process.env.COAS_URL.replace(":batchId", batchId)
  );
  const url = coaData?.data?.[0]?.coa_url;
  return url ? { url } : null;
}

// apis/customer-integration/customerIntegration.service.ts
import axios5 from "axios";
var CustomerIntegration = class _CustomerIntegration {
  static loyaltyBaseUrl = process.env.LOYALTY_BASE_URL;
  static getLoyaltyUrl() {
    return `${_CustomerIntegration.loyaltyBaseUrl}`;
  }
  static async fetchMemberTransactions(user) {
    return axios5.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/FetchMemberTransactions`,
      user
    );
  }
  // Adds customer to customer integration
  static async addCustomer(user) {
    return axios5.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/AddCustomer`,
      user
    );
  }
  // Adds customer to Loyalty
  // TODO: Likely can remove now.  'AddCustomer' is now handling this
  static async addCustomerToLoyalty(user) {
    return axios5.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/AddCustomerToLoyalty`,
      user
    );
  }
  static async fetchMemberRankingStatus(user) {
    return axios5.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/FetchMemberRankingSetStatus`,
      user
    );
  }
  static async fetchMemberPointBalances(user) {
    return axios5.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/FetchMemberPointBalances`,
      user
    );
  }
  // TODO: Potentially remove as this is no longer available
  // Should be getting replaced by general delete
  static async deleteFromCustomerIntegration({
    customerId
  }) {
    return axios5.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/DeleteCustomer`,
      { id: customerId }
    );
  }
  static async getCustomerIntegrationId(user) {
    return axios5.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/GetCuraleafId`,
      user
    );
  }
  static async processReferredBy(referrerMemberAccountId, referreeMemberAccountId) {
    return axios5.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/ProcessReferredBy`,
      {
        referrerMemberAccountId,
        referreeMemberAccountId
      }
    );
  }
  static async issueInteractionUserDefined(memberAccountId, interactionTypeExternalReference, locationExternalReference = "CuraleafOnline", interactionNote = "Added from the curaleaf graph programmatically.") {
    return axios5.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/IssueInteractionUserDefined`,
      {
        memberAccountId,
        interactionTypeExternalReference,
        locationExternalReference,
        interactionNote
      }
    );
  }
};

// apis/contentstack/contentstack.service.ts
import axios6 from "axios";

// queries/contentstack/website.ts
import { gql as gql2 } from "graphql-request";

// queries/contentstack/fragments.ts
import { gql } from "graphql-request";
var imageFragment = gql`
  fragment imageFragment on SysAssetConnection {
    edges {
      node {
        description
        dimension {
          height
          width
        }
        url
      }
    }
  }
`;
var seoFragment = gql`
  seo {
    imageConnection {
      ...imageFragment
    }
    no_follow
    no_index
    page_description
    page_title
  }
`;

// queries/contentstack/website.ts
var ageVerificationQuery = `age_verification {
  background_imageConnection {
  ...imageFragment
  }
  buttons {
  button_variation
  exit_redirect_link
  exit_text
  submit_button_text
  }
  legal_subtext
  legal_text
  title
  }
  `;
var optInModalQuery = `opt_in_modal {
  delay
  embeddable_formConnection {
  edges {
  node {
  ... on EmbeddableForm {
  title
  subtitle
  type
  dropdowntype
  system {
  uid
  }
  braze_custom_event_name
  confirmation {
  content
  imageConnection {
  ...imageFragment
  }
  title
  }
  contact_us {
  contact_us_email_destination
  subject_line
  }
  dispensariesConnection {
  edges {
  node {
  ... on DispensaryConfig {
  friendly_name
  title
  slug
  }
  }
  }
  }
  form_title
  imageConnection {
  ...imageFragment
  }
  statesConnection {
  edges {
  node {
  ... on States {
  title
  abbreviation
  slug
  }
  }
  }
  }
  }
  }
  }
  }
  }
  `;
var footerQuery = `footer {
    footer_disclaimer
    copyright_info
    footer_links {
    url
    heading
    links {
    link_name
    pageConnection {
    edges {
    node {
    ... on LandingPage {
    title
    slug
    }
    }
    }
    }
    url
    }
    }
    legal_links {
    link_text
    url
    pageConnection {
    edges {
    node {
    ... on LandingPage {
    title
    slug
    }
    }
    }
    }
    }
    social_media_links {
    corporate_type
    url
    }
    }
  `;
var wideBannerQuery = `
  wide_banners {
    auto_slide_in_seconds
    auto_slide_on_desktop
    auto_slide_on_mobile
    banners {
    desktop_text
    mobile_text
    url
    }
  }
`;
var ContentPageWebsiteQuery = gql2`
  ${imageFragment}
  query Website {
    all_website {
      items {
        ${ageVerificationQuery}
        ${optInModalQuery}
        ${footerQuery}
        ${wideBannerQuery}
      }
    }
  }
`;

// queries/contentstack/dispensary.ts
var dispensaryFragment = (forWeb = true) => `
  items {
    admin {
      disabled_button_text
      pause_ecommerce
    }
    app_config {
      dutchie_retailer_id
      enabled
    }
    auto_syncConnection {
      edges {
        node {
          ... on Dispensarysync {
            unique_id
            address
            city
            country
            day_times
            dst
            email
            google_maps_uri
            latitude
            longitude
            overview
            overview_photos
            phone
            zipcode
            timezone
            photo_url
          }
        }
      }
    }
    curaleaf_internal_id
    debit_fee_text
    delivery {
      delivery_only
      disclaimer
      dispensary_type_delivery
    }
    dispensary_overview_override
    dispensary_type
    extra_footer_text
    friendly_name
    geolocated_kiosk_url {
      max_view_distance
      menu_url
    }
    ${forWeb ? "" : "ll_api_key"}
    map_imageConnection {
      edges {
        node {
          dimension {
            width
            height
          }
          url
        }
      }
    }
    slug
    statesConnection {
      edges {
        node {
          ... on States {
            title
            lab_results_url
            abbreviation
            form_config {
            bypass_collecting_medical_card
            }
            hipaa_enabled
            nationally_suppress
            slug
            ${forWeb ? "" : "spring_big_auth_token"}
          }
        }
      }
    }
    store_offerings
    storefront {
      ... on DispensaryConfigStorefrontPreRelease {
        __typename
        pre_release {
          description
          title
        }
      }
      ... on DispensaryConfigStorefrontDutchiePlus {
        __typename
        Dutchie_Plus {
          retailer_id
          storefrontConnection {
            edges {
              node {
                ... on Storefront {
                  cta_banner {
                    desktop_text
                    mobile_text
                    url
                  }
                  hide_flower_equivalent
                  hide_programmatic_categories
                  hide_staff_picks
                  information_banner {
                    cta {
                      button_text
                      url_destination
                    }
                    description
                    severity
                    title
                  }
                  survey_monkey_feedback_url
                  product_listing {
                      ... on StorefrontProductListingPosTag {
                          __typename
                          pos_tag {
                              tag
                              title
                          }
                      }
                      ... on StorefrontProductListingDutchieProductList {
                          __typename
                          dutchie_product_list {
                              title
                          }
                      }
                      ... on StorefrontProductListingDynamicList {
                          __typename
                          dynamic_list {
                              brands
                              categories
                              name_includes
                              title
                          }
                      }
                  }
                }
              }
            }
          }
          menu_itemsConnection {
            edges {
              node {
                ... on ShopMenu {
                  title
                  menu_items {
                    category_imageConnection {
                      ... imageFragment
                    }
                    display_name
                    category_name_override
                  }
                }
              }
            }
          }
        }
      }
      ... on DispensaryConfigStorefrontExternal {
        __typename
        external {
          url
          app_url
          modal_primary_button_text
          modal_title
          external_type
        }
      }
    }
    unique_id
    system {
      uid
    }
  }
  total
`;
var DispensaryQuery = (filter = "", forWeb = true) => `
  ${imageFragment}
  query Dispensary {
    all_dispensary_config ${filter} {
      ${dispensaryFragment(forWeb)}
    }
  }
`;
var DispensariesQuery = (filter = "", forWeb = true) => `
  ${imageFragment}
  query Dispensary {
    all_dispensary_config ${filter} {
      ${dispensaryFragment(forWeb)}
    }
  }
`;
var DefaultDispensaryQuery = `
query DefaultDispensary {
  all_dispensary_config(
    where: {storefront: {Dutchie_Plus_exists: true}}
    limit: 1
  ) {
    items {
      unique_id
    }
  }
}`;
var StoreTypeQuery = (filter = "") => `
  ${imageFragment}
  query Dispensary {
    all_dispensary_config ${filter} {
      items {
        app_config {
          dutchie_retailer_id
        }
        auto_syncConnection {
          edges {
            node {
              ... on Dispensarysync {
                unique_id
                address
                city
                country
                day_times
                dst
                email
                google_maps_uri
                latitude
                longitude
                phone
                zipcode
                timezone
              }
            }
          }
        }
        curaleaf_internal_id
        debit_fee_text
        delivery {
          delivery_only
          disclaimer
          dispensary_type_delivery
        }
        dispensary_overview_override
        dispensary_type
        extra_footer_text
        friendly_name
        geolocated_kiosk_url {
          max_view_distance
          menu_url
        }
        slug
        statesConnection {
          edges {
            node {
              ... on States {
                title
                lab_results_url
                abbreviation
                form_config {
                bypass_collecting_medical_card
                }
                hipaa_enabled
                nationally_suppress
                seo {
                  canonical
                  imageConnection {
                    ... imageFragment
                  }
                  page_description
                  page_title
                }
                slug
                state_location_page {
                  seo_friendly_copy
                }
              }
            }
          }
        }
        store_offerings
        storefront {
          ... on DispensaryConfigStorefrontPreRelease {
            __typename
          }
          ... on DispensaryConfigStorefrontDutchiePlus {
            __typename
            Dutchie_Plus {
              retailer_id
            }
          }
          ... on DispensaryConfigStorefrontExternal {
            __typename
            external {
              url
            }
          }
        }
        unique_id
        system {
          uid
        }
      }
      total
    }
  }
`;
var OrderHistoryDispensariesQuery = (filter = "") => `
  query Dispensary {
    all_dispensary_config ${filter} {
      items {
        curaleaf_internal_id
        friendly_name
      }
      total
    }
  }
`;

// utils/randomize.ts
var randomizeHdyhau = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    if (temp) {
      array[i] = array[j];
      array[j] = temp;
    }
  }
  return array;
};

// queries/contentstack/landingPage.ts
import { gql as gql3 } from "graphql-request";

// queries/contentstack/stateComponents.ts
var stateComponents = {
  TextBlock: `
    align
    anchor
    content
    ctabutton {
      ...ctabuttonFragment
    }
    header
  `
};

// queries/contentstack/landingPage.ts
var ctaFragment = gql3`
  fragment ctabuttonFragment on CtaButton {
    text
    url
    variant
    background_hex_color
    border_hex_color
    text_hex_color
  }
`;
var LandingPageTypeNameQuery = gql3`
  query LandingPageComponentsTypename($url: String!) {
    all_landing_page(where: { url: $url }, limit: 15) {
      items {
        page_components {
          ... on LandingPagePageComponentsTextblock {
            __typename
          }
        }
      }
    }
  }
`;
var heroStackedBannerFragment = gql3`
... on HeroStackedBanner {
  anchor
  cta_buttons {
    ...ctabuttonFragment
  }
  heading
  hide_logo
  imageConnection {
    ...imageFragment
  }
  mobile_assetConnection {
    ...imageFragment
  }
  mode
  subtitle
  title
}
`;
var buildDynamicLandingPageQuery = (pageComponents) => {
  let pageComponentsFragment = ``;
  const typeNames = pageComponents.map(
    (pc) => pc.__typename
  );
  const filtered = pageComponents.filter(
    ({ __typename }, index) => !typeNames.includes(__typename, index + 1)
  );
  for (let i = 0; i < filtered.length; i++) {
    const componentTypename = filtered[i]?.__typename;
    if (componentTypename && PageComponentFragments[componentTypename]) {
      pageComponentsFragment += PageComponentFragments[componentTypename];
    }
  }
  return gql3`
    ${ctaFragment}
    ${imageFragment}
    query DynamicLandingPage($url: String!) {
      all_landing_page(where: { url: $url }, limit: 15) {
        items {
          url
          title
          websites
          breadcrumbs
          seo {
            canonical_url
            imageConnection {
              ...imageFragment
            }
            no_follow
            no_index
            page_description
            page_title
          }
          page_components {
            ... on LandingPagePageComponentsHeroStacked {
              __typename
              hero_stacked {
                heroConnection {
                  edges {
                    node {
                      ${heroStackedBannerFragment}
                    }
                  }
                }
              }
            }
            ${pageComponentsFragment}
          }
        }
      }
    }
  `;
};
var PageComponentFragments = {
  LandingPagePageComponentsTextblock: gql3`
    ... on LandingPagePageComponentsTextblock {
      __typename
      textblock {
        textblockConnection {
          edges {
            node {
              ... on Textblock {
                ${stateComponents.TextBlock}
              }
            }
          }
        }
      }
    }
  `
};

// queries/contentstack/state.ts
var state_fragment = (forWeb = true) => `
  items {
    abbreviation
    hipaa_enabled
    lab_results_url
    slug
    show_transparency_link
    ${forWeb ? "" : "spring_big_auth_token"}
    title
    system {
      uid
    }
    curaleaf_com {
      copy
    }
    form_config {
      bypass_collecting_medical_card
      has_out_of_state_med
      has_out_of_state_rec
    }
    state_location_page {
      header_imageConnection {
        ... imageFragment
      }
      seo_friendly_copy
    }
  }
`;
var AllStatesQuery = (forWeb = true) => `
  ${imageFragment}
  query MyQuery  {
    all_states (limit: 100, skip: 0) {
      ${state_fragment(forWeb)}
    }
  }
`;
var LocationsPageStateQuery = (filter = "") => `
  ${imageFragment}
  query MyQuery {
    all_states ${filter} {
      items {
        curaleaf_com {
          copy
        }
        slug
        state_location_page {
          header_imageConnection {
            ...imageFragment
          }
          seo_friendly_copy
        }
        title
      }
    }
  }
`;

// queries/contentstack/curaleafPage.ts
import { gql as gql4 } from "graphql-request";
var CuraleafSeoQuery = gql4`
  ${imageFragment}
  query CuraleafPageSeo($url: String!) {
    all_curaleaf_page(where: { url: $url }) {
      items {
        ${seoFragment}
      }
    }
  }
`;
var CuraleafPageComponentsTypeNameQuery = gql4`
  query CuraleafPageComponentsTypeName($url: String!) {
    all_curaleaf_page(where: { url: $url }, limit: 15) {
      items {
        modular_blocks {
          ... on CuraleafPageModularBlocksArticleGrid {
            __typename
          }
          ... on CuraleafPageModularBlocksCustomComponent {
            __typename
          }
          ... on CuraleafPageModularBlocksCtaDivider {
            __typename
          }
          ... on CuraleafPageModularBlocksDecisionTree {
            __typename
          }
          ... on CuraleafPageModularBlocksEmbeddableForm {
            __typename
          }
          ... on CuraleafPageModularBlocksFaqBlock {
            __typename
          }
          ... on CuraleafPageModularBlocksHeroBannerCarousel {
            __typename
          }
          ... on CuraleafPageModularBlocksIframe {
            __typename
          }
          ... on CuraleafPageModularBlocksImageBlock {
            __typename
          }
          ... on CuraleafPageModularBlocksInstagramGrid {
            __typename
          }
          ... on CuraleafPageModularBlocksInstructionsBlock {
            __typename
          }
          ... on CuraleafPageModularBlocksMultiTextImageBlock {
            __typename
          }
          ... on CuraleafPageModularBlocksProductListing {
            __typename
          }
          ... on CuraleafPageModularBlocksProductCard {
            __typename
          }
          ... on CuraleafPageModularBlocksPromoCarousel {
            __typename
          }
          ... on CuraleafPageModularBlocksRedirectSearch {
            __typename
          }
          ... on CuraleafPageModularBlocksRewardsTable {
            __typename
          }
          ... on CuraleafPageModularBlocksTextImageBlock {
            __typename
          }
          ... on CuraleafPageModularBlocksVideoBlock {
            __typename
          }
        }
      }
    }
  }
`;
var buildDynamicCuraleafPageQuery = (pageComponents) => {
  let pageComponentsFragment = ``;
  const typeNames = pageComponents.map(
    (pc) => pc.__typename
  );
  const filtered = pageComponents.filter(
    ({ __typename }, index) => !typeNames.includes(__typename, index + 1)
  );
  for (let i = 0; i < filtered.length; i++) {
    const componentTypename = filtered[i]?.__typename;
    if (componentTypename && ModularBlockFragments[componentTypename]) {
      pageComponentsFragment += ModularBlockFragments[componentTypename];
    }
  }
  return gql4`
    ${imageFragment}
    query CuraleafPage($url: String!) {
      all_curaleaf_page(where: { url: $url }, limit: 15) {
        items {
          url
          ${seoFragment}
          breadcrumbs
          modular_blocks {
            ${pageComponentsFragment}
          }
        }
      }
    }
  `;
};
var ModularBlockFragments = {
  CuraleafPageModularBlocksArticleGrid: `
    ... on CuraleafPageModularBlocksArticleGrid {
      __typename
      article_grid {
        articlesConnection {
          edges {
            node {
              ... on Article {
                article_card {
                  thumbnailConnection {
                    ...imageFragment
                  }
                }
                author
                category
                published_date
                slug
                title
              }
              ... on NewsItem {
                imageConnection {
                  ...imageFragment
                }
                published_date
                publisher
                title
                website_url
              }
            }
          }
        }
        heading
      }
    }`,
  CuraleafPageModularBlocksCustomComponent: `
    ... on CuraleafPageModularBlocksCustomComponent {
      __typename
      custom_component {
        type
      }
    }`,
  CuraleafPageModularBlocksCtaDivider: `
    ... on CuraleafPageModularBlocksCtaDivider {
      __typename
      cta_divider {
        link
        logged_in_cta_text
        logged_out_cta_text
        mode
        text
      }
    }`,
  CuraleafPageModularBlocksDecisionTree: gql4`
    ... on CuraleafPageModularBlocksDecisionTree {
      __typename
      decision_tree {
        anchor
        decision_treeConnection {
          edges {
            node {
              ... on DecisionTreeQuestions {
                question
                system {
                  uid
                }
              }
            }
          }
        }
        starting_header
        tag
      }
    }
  `,
  CuraleafPageModularBlocksEmbeddableForm: gql4`
    ... on CuraleafPageModularBlocksEmbeddableForm {
      __typename
      embeddable_form {
        formConnection {
          edges {
            node {
              ... on EmbeddableForm {
                system {
                  uid
                }
                type
                dropdowntype
                confirmation {
                  title
                  content
                  imageConnection {
                    ...imageFragment
                  }
                }
                dispensariesConnection {
                  edges {
                    node {
                      ... on DispensaryConfig {
                        friendly_name
                        slug
                      }
                    }
                  }
                }
                form_title
                imageConnection {
                  ...imageFragment
                }
                statesConnection {
                  edges {
                    node {
                      ... on States {
                        title
                        abbreviation
                        slug
                      }
                    }
                  }
                }
                subtitle
              }
            }
          }
        }
      }
    }
  `,
  CuraleafPageModularBlocksFaqBlock: `
    ... on CuraleafPageModularBlocksFaqBlock {
      __typename
      faq_block {
        anchor
        content
        faqs {
          answer
          question
        }
      }
    }`,
  CuraleafPageModularBlocksHeroBannerCarousel: `
    ... on CuraleafPageModularBlocksHeroBannerCarousel {
      __typename
      hero_banner_carousel {
        anchor
        banner {
          content
          cta_button {
            link
            text
            variant
          }
          heading
          image {
            desktopConnection {
              ...imageFragment
            }
            mobileConnection {
              ...imageFragment
            }
          }
          mobile_variant
          show_logo
          variant
        }
      }
    }`,
  CuraleafPageModularBlocksIframe: `
    ... on CuraleafPageModularBlocksIframe {
      __typename
      iframe {
        src
      }
    }`,
  CuraleafPageModularBlocksImageBlock: `
    ... on CuraleafPageModularBlocksImageBlock {
      __typename
      image_block {
        anchor
        cta {
          ... on CuraleafPageModularBlocksImageBlockBlockCtaCtaButton {
            __typename
            cta_button {
              link
              text
              variant
            }
          }
          ... on CuraleafPageModularBlocksImageBlockBlockCtaCtaLink {
            __typename
            cta_link {
              aria_label
              link
            }
          }
        }
        desktopConnection {
          ...imageFragment
        }
        mobileConnection {
          ...imageFragment
        }
      }
    }`,
  CuraleafPageModularBlocksInstagramGrid: `
    ... on CuraleafPageModularBlocksInstagramGrid {
      __typename
      instagram_grid {
        image {
          imageConnection {
            ...imageFragment
          }
          link
        }
      }
    }`,
  CuraleafPageModularBlocksInstructionsBlock: `
    ... on CuraleafPageModularBlocksInstructionsBlock {
      __typename
      instructions_block {
        heading
        steps
      }
    }`,
  CuraleafPageModularBlocksMultiTextImageBlock: `
    ... on CuraleafPageModularBlocksMultiTextImageBlock {
      __typename
      multi_text_image_block {
        align
        anchor
        heading
        image_fit
        column {
          content
        }
        cta_buttons {
          link
          text
          variant
        }
        dispensary_card {
          dispensaryConnection {
            edges {
              node {
                ... on DispensaryConfig {
                  app_config {
                    dutchie_retailer_id
                    enabled
                  }
                  auto_syncConnection {
                    edges {
                      node {
                        ... on Dispensarysync {
                          unique_id
                          address
                          city
                          country
                          day_times
                          dst
                          email
                          google_maps_uri
                          latitude
                          longitude
                          phone
                          zipcode
                        }
                      }
                    }
                  }
                  curaleaf_internal_id
                  debit_fee_text
                  delivery {
                    delivery_only
                    disclaimer
                    dispensary_type_delivery
                  }
                  dispensary_type
                  friendly_name
                  slug
                  statesConnection {
                    edges {
                      node {
                        ... on States {
                          title
                          abbreviation
                          slug
                        }
                      }
                    }
                  }
                  store_offerings
                  storefront {
                    ... on DispensaryConfigStorefrontPreRelease {
                      __typename
                    }
                    ... on DispensaryConfigStorefrontDutchiePlus {
                      __typename
                    }
                    ... on DispensaryConfigStorefrontExternal {
                      __typename
                      external {
                        url
                        app_url
                        modal_primary_button_text
                        modal_title
                        external_type
                      }
                    }
                  }
                  unique_id
                  system {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }`,
  CuraleafPageModularBlocksProductCard: `
    ... on CuraleafPageModularBlocksProductCard {
      __typename
      product_card {
        anchor
        cta_button {
          link
          text
          variant
        }
        features {
          content
        }
        heading
        imageConnection {
          ...imageFragment
        }
      }
    }`,
  CuraleafPageModularBlocksProductListing: `
    ... on CuraleafPageModularBlocksProductListing {
      __typename
      product_listing {
        category
        heading
      }
    }`,
  CuraleafPageModularBlocksPromoCarousel: `
    ... on CuraleafPageModularBlocksPromoCarousel {
      __typename
      promo_carousel {
        anchor
        variant
        promo_banner {
          background_color_hex
          content
          cta_button {
            link
            text
            variant
          }
          image {
            desktopConnection {
              ...imageFragment
            }
            mobileConnection {
              ...imageFragment
            }
          }
          layout
        }
      }
    }`,
  CuraleafPageModularBlocksRedirectSearch: `
    ... on CuraleafPageModularBlocksRedirectSearch {
      __typename
      redirect_search {
        anchor
        background_imageConnection {
          ...imageFragment
        }
        button_text
        heading
        input_placeholder
        search_url
      }
    }`,
  CuraleafPageModularBlocksRewardsTable: `
    ... on CuraleafPageModularBlocksRewardsTable {
      __typename
      rewards_table {
        heading
        table_row {
          cell {
            color
            icon
            markdown
          }
        }
      }
    }`,
  CuraleafPageModularBlocksTextImageBlock: `
    ... on CuraleafPageModularBlocksTextImageBlock {
      __typename
      text_image_block {
        anchor
        content
        cta_button {
          link
          text
          variant
        }
        imageConnection {
          ...imageFragment
        }
        layout
      }
    }`,
  CuraleafPageModularBlocksVideoBlock: `
    ... on CuraleafPageModularBlocksVideoBlock {
      __typename
      video_block {
        videoConnection {
          edges {
            node {
              url
            }
          }
        }
      }
    }`
};

// utils/careersMap.ts
var sortJobs = (a, b) => {
  if (a.department > b.department) {
    return 1;
  } else if (a.department < b.department) {
    return -1;
  } else {
    if (a.title > b.title) {
      return 1;
    } else if (a.title < b.title) {
      return -1;
    } else {
      if (a.location > b.location) {
        return 1;
      } else if (a.location < b.location) {
        return -1;
      } else {
        return 0;
      }
    }
  }
};
var sortStates = (a, b) => {
  if (a.title > b.title) {
    return 1;
  } else if (a.title < b.title) {
    return -1;
  }
  return 0;
};
var getJobsByStates = (states, department) => {
  let jobs = [];
  states.push({
    title: "All"
  });
  const data = states.sort((a, b) => sortStates(a, b)).map((state) => {
    let label = "";
    jobs = department.filter((job) => {
      if (job.state === state.abbreviation) {
        label = state.title;
      }
      if (state.title === "All") {
        label = state.title;
      }
      return job.state === state.abbreviation || label === "All";
    });
    jobs = jobs.sort((a, b) => sortJobs(a, b));
    const children = [];
    jobs.map((job) => {
      const match = children.find(
        (child) => child.department === job.department
      );
      if (match) {
        match.listing.push({
          title: job.title,
          url: job.url,
          location: job.location
        });
      } else {
        children.push({
          department: job.department,
          listing: [
            {
              title: job.title,
              url: job.url,
              location: job.location
            }
          ]
        });
      }
      return match;
    });
    return {
      label,
      children
    };
  });
  return data.filter((career) => career.label && career.label.length);
};
var getDepartmentJobs = (departments, states) => {
  const deptJobs = [];
  departments.map((department) => {
    department.jobs.map((job) => {
      const location = job.location.name;
      let state = null;
      let city = null;
      const splitLocation = location.split(",");
      if (splitLocation?.length > 1) {
        state = splitLocation[1].trim().replace(/[^a-z]/gi, "");
        city = splitLocation[0].trim();
      } else if (splitLocation?.length === 1) {
        state = splitLocation[0].trim().replace(/[^a-z]/gi, "");
      }
      const stateMatch = states.find(
        (s) => s.abbreviation.toLowerCase() === state?.toLowerCase() || s.title.toLowerCase() === state?.toLowerCase()
      );
      return deptJobs.push({
        department: department.name,
        title: job.title,
        url: job.absolute_url,
        location: city && stateMatch ? `${city}, ${stateMatch.abbreviation}` : !city && stateMatch ? `${stateMatch.title}` : location,
        state: stateMatch ? `${stateMatch.abbreviation}` : `${state}`
      });
    });
  });
  return deptJobs;
};

// utils/decisionTreeMap.ts
function updateMatchingQuestion(obj, count, allQuestions) {
  for (const [key, value] of Object.entries(obj)) {
    if (count >= 10 || key !== "answers") {
      return obj;
    } else if (key === "answers" && value && typeof obj === "object" && obj.answers[0]) {
      for (let i = 0; i < obj.answers.length; i++) {
        if (obj.answers[i].node.questionConnection.edges[0].node.system) {
          const matchingQuestion = allQuestions ? allQuestions.find(
            (question) => question.system.uid === obj.answers[i].node.questionConnection.edges[0].node.system.uid
          ) : null;
          obj.answers[i] = {
            node: obj.answers[i].node,
            reference: {
              answers: matchingQuestion ? matchingQuestion.answersConnection.edges : null,
              question: matchingQuestion ? matchingQuestion.question : ""
            },
            text: obj.answers[i].node.text
          };
          updateMatchingQuestion(
            obj.answers[i].reference,
            count += 1,
            allQuestions
          );
        } else {
          return obj;
        }
      }
    }
  }
}
var calculateDecisionTree = async (decisionTree) => {
  const contentstack3 = new ContentStackService();
  const decisionTreeReq = await contentstack3.getDecisionTreeData(
    decisionTree.decision_tree,
    false
  );
  if (decisionTreeReq) {
    return {
      __typename: "CuraleafPageModularBlocksDecisionTree",
      decision_tree: {
        anchor: decisionTree.decision_tree.anchor,
        questions: decisionTreeReq,
        startingHeader: decisionTree.decision_tree.starting_header
      }
    };
  } else {
    console.warn("Error: Failed to get decision tree data");
    return null;
  }
};

// apis/contentstack/contentstack.service.ts
var ContentStackService = class {
  baseUrl = "https://graphql.contentstack.com/stacks";
  apiKey = process.env.CONTENTSTACK_API_KEY;
  token = process.env.CONTENTSTACK_ACCESS_TOKEN;
  environment = process.env.CONTENTSTACK_ENVIRONMENT;
  previewEnvironment = process.env.CONTENTSTACK_PREVIEW_ENVIRONMENT;
  previewToken = process.env.CONTENTSTACK_PREVIEW_ACCESS_TOKEN;
  generateRequestUrl(isPreview) {
    return `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`;
  }
  generateRequestHeaders(isPreview) {
    return { access_token: isPreview ? this.previewToken : this.token };
  }
  getLandingPageByUrl(url, isPreview) {
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query: LandingPageTypeNameQuery,
        variables: { url }
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      if (data.data.all_landing_page.items[0]) {
        return axios6.post(
          this.generateRequestUrl(isPreview),
          {
            query: buildDynamicLandingPageQuery(
              data.data.all_landing_page.items[0].page_components
            ),
            variables: { url }
          },
          {
            headers: this.generateRequestHeaders(isPreview)
          }
        ).then(({ data: data2 }) => {
          return {
            success: true,
            data: data2.data.all_landing_page
          };
        });
      }
      throw new Error("Failed to fetch LandingPageTypeNameQuery");
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getSitemapData() {
    const urls = [];
    const req = await axios6.post(
      this.generateRequestUrl(false),
      {
        query: `
query getSitemapData {
  all_curaleaf_page(limit: 200) {
    items {
      url
      system {
        updated_at
      }
    }
  }
  all_dispensary_config(limit: 200) {
    items {
      slug
      statesConnection {
        edges {
          node {
            ... on States {
              slug
            }
          }
        }
      }
      system {
        updated_at
      }
    }
  }
  all_article(limit: 200) {
    items {
      slug
      category
      system {
        updated_at
      }
    }
  }
  all_states(limit: 200) {
    items {
      system {
        updated_at
      }
      slug
    }
  }
}
        `
      },
      {
        headers: this.generateRequestHeaders(false)
      }
    );
    for (const page of req.data.data.all_curaleaf_page.items) {
      urls.push({
        url: `${process.env.WEBSITE_URL}${page.url}`,
        // has leading slashes on the urls
        lastModified: new Date(page.system.updated_at).toISOString(),
        priority: 2,
        changeFrequency: "weekly"
      });
    }
    for (const dispo of req.data.data.all_dispensary_config.items) {
      urls.push({
        url: `${process.env.WEBSITE_URL}/shop/${dispo.statesConnection.edges[0].node.slug}/${dispo.slug}`,
        lastModified: new Date(dispo.system.updated_at).toISOString(),
        priority: 1,
        changeFrequency: "always"
      });
      urls.push({
        url: `${process.env.WEBSITE_URL}/dispensary/${dispo.statesConnection.edges[0].node.slug}/${dispo.slug}`,
        lastModified: new Date(dispo.system.updated_at).toISOString(),
        priority: 1,
        changeFrequency: "always"
      });
    }
    for (const article of req.data.data.all_article.items.filter(
      (item) => !item.slug.includes("http")
    )) {
      urls.push({
        url: `${process.env.WEBSITE_URL}/${article.category === "blog" ? "blog" : "cooking-with-cannabis"}/${article.slug}`,
        lastModified: new Date(article.system.updated_at).toISOString(),
        priority: 3,
        changeFrequency: "monthly"
      });
    }
    urls.push({
      url: `${process.env.WEBSITE_URL}/locations`,
      priority: 1,
      changeFrequency: "daily"
    });
    return urls;
  }
  async getDispensaryData(forWeb = true, isPreview, forGraphApi, stateSlug) {
    const pageSize = 100;
    const origDispensaries = [];
    const isStateSite = !!(process.env.CONTENTSTACK_ENVIRONMENT?.includes("ct") || process.env.CONTENTSTACK_ENVIRONMENT?.includes("nd"));
    const filters = () => {
      if (stateSlug) {
        return `(limit: ${pageSize}, skip: 0, where: {states: {states: {slug: "${stateSlug}"}}})`;
      } else if (forGraphApi) {
        return `(limit: ${pageSize}, skip: 0)`;
      } else if (isStateSite) {
        const stateAbbr = process.env.CONTENTSTACK_ENVIRONMENT?.split("-")[0]?.toUpperCase();
        return `(limit: ${pageSize}, skip: 0, where: {states: {states: {abbreviation_eq: ${stateAbbr}}}})`;
      } else {
        return `(limit: ${pageSize}, skip: 0, where: {states: {states: {abbreviation_nin: ["CT", "ND"]}}})`;
      }
    };
    const firstRequest = await this.fetchDispensaries(
      stateSlug ? "location" : "dispensaries",
      {},
      filters(),
      isPreview,
      forWeb
    );
    if (firstRequest.success) {
      const total = firstRequest.data.all_dispensary_config?.total;
      origDispensaries.push(...firstRequest.data.all_dispensary_config.items);
      const callCount = Math.ceil(total / pageSize);
      if (total > pageSize) {
        for (let i = 1; i < callCount; i++) {
          const newFilters = () => {
            if (forGraphApi) {
              return `(limit: ${pageSize}, skip: ${i * pageSize})`;
            } else if (isStateSite) {
              const stateAbbr = process.env.CONTENTSTACK_ENVIRONMENT?.split(
                "-"
              )[0]?.toUpperCase();
              return `(limit: ${pageSize}, skip: ${i * pageSize}, where: {states: {states: {abbreviation_eq: ${stateAbbr}}}})`;
            } else {
              return `(limit: ${pageSize}, skip: ${i * pageSize}, where: {states: {states: {abbreviation_nin: ["CT", "ND"]}}})`;
            }
          };
          const newReq = await this.fetchDispensaries("dispensaries", {}, newFilters(), isPreview, forWeb);
          if (newReq.success) {
            origDispensaries.push(...newReq.data.all_dispensary_config.items);
          }
        }
      }
      return {
        success: true,
        data: origDispensaries.filter((oDispo) => oDispo.auto_syncConnection?.edges?.length).map((oDispo) => {
          try {
            const { statesConnection, auto_syncConnection, ...tmpDisp } = oDispo;
            return {
              ...tmpDisp,
              ...auto_syncConnection.edges[0].node,
              day_times: auto_syncConnection.edges[0]?.node.day_times ? JSON.parse(auto_syncConnection.edges[0].node.day_times) : [],
              distance: "",
              state: statesConnection.edges[0].node,
              unique_id: oDispo.unique_id,
              uid: oDispo.unique_id
            };
          } catch (err) {
            console.error(err);
          }
        }).filter(
          (dispo) => dispo && dispo.state && (forGraphApi || isStateSite || !dispo.state.nationally_suppress)
        )
      };
    }
    return {
      success: false,
      errorMessage: ""
    };
  }
  async getADispensary(uid, forWeb = true, isPreview, slug) {
    let filter = `(where: {unique_id: "${uid}"})`;
    if (slug) {
      filter = `(where: {slug: "${slug}"})`;
    }
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query: DispensaryQuery(filter, forWeb)
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      const dispensary_config = data.data.all_dispensary_config.items[0];
      const { statesConnection, auto_syncConnection, ...restDisp } = dispensary_config;
      if (!auto_syncConnection.edges[0])
        throw new Error(
          `Dispensary (uid: ${uid}) is missing auto sync connection in contentstack`
        );
      const { day_times, ...restAutoSync } = auto_syncConnection.edges[0].node;
      const dayTimes = JSON.parse(day_times);
      const state = statesConnection.edges[0]?.node;
      const isStateSite = !!(process.env.CONTENTSTACK_ENVIRONMENT?.includes("ct") || process.env.CONTENTSTACK_ENVIRONMENT?.includes("nd"));
      if (!state || !isStateSite && state.nationally_suppress) {
        throw new Error(
          `Dispensary (uid: ${uid}) is missing state connection`
        );
      }
      const dispData = {
        ...restDisp,
        ...restAutoSync,
        day_times: dayTimes,
        state,
        distance: "",
        uid: restDisp.unique_id
      };
      return {
        success: true,
        data: dispData
      };
    }).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async getFormConfig(isPreview, cache = true) {
    if (cache) {
      try {
        const res = await this.getFormConfigCuraql();
        return res;
      } catch (err) {
      }
    }
    const query = `
      query FormConfiguration {
        all_Form_Configuration {
          items {
            contact_us {
              topics {
                display_value
              }
            }
            in_store_intake {
              disclaimer_copy
              form_title
              marketing_intake_checkbox_copy
              submit_button_text
              success_message
              success_title
              success_close_button_text
              timeouts {
                modal_button_text
                modal_title
                popup_time
                time_to_dismiss
              }
            }
            general {
              disclaimer
              how_did_you_hear_about_us {
                display_text
                value
              }
              interests {
                display_text
                value
              }
              submit_button_text
            }
          }
        }
      }
    `;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      if (data.data.all_Form_Configuration.items[0]) {
        data.data.all_Form_Configuration.items[0].general.how_did_you_hear_about_us = randomizeHdyhau(
          data.data.all_Form_Configuration.items[0].general.how_did_you_hear_about_us
        );
        return {
          success: true,
          data: data.data.all_Form_Configuration.items[0]
        };
      }
      throw new Error("Missing items in all_Form_Configuration");
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getFormConfigCuraql() {
    if (process.env.CURAQL_BASEURL) {
      const query = `
      query FormConfiguration {
        formConfig {
          contact_us {
            topics {
              display_value
            }
          }
          in_store_intake {
            disclaimer_copy
            form_title
            marketing_intake_checkbox_copy
            submit_button_text
            success_message
            success_title
            success_close_button_text
            timeouts {
              modal_button_text
              modal_title
              popup_time
              time_to_dismiss
            }
          }
          general {
            disclaimer
            how_did_you_hear_about_us {
              display_text
              value
            }
            interests {
              display_text
              value
            }
            submit_button_text
          }
        }
      }
    `;
      return axios6.post(process.env.CURAQL_BASEURL, {
        query
      }).then(
        ({ data }) => {
          if (data.data.formConfig) {
            data.data.formConfig.general.how_did_you_hear_about_us = randomizeHdyhau(
              data.data.formConfig.general.how_did_you_hear_about_us
            );
          }
          return {
            success: true,
            data: data.data.formConfig
          };
        }
      ).catch(
        (err) => ({
          success: false,
          errorMessage: err
        })
      );
    } else {
      return {
        success: false,
        errorMessage: "missing CURAQL_BASEURL"
      };
    }
  }
  getStatesData(isPreview = false, forWeb = true) {
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query: AllStatesQuery(forWeb)
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      return {
        success: true,
        data: data.data.all_states.items.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          } else if (a.title > b.title) {
            return 1;
          } else return 0;
        })
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getLayoutData(isPreview = false) {
    const query = `
      query WebsiteLayout {
        all_website_layout {
          items {
            header {
              menu_items {
                sub_menu_items {
                  sub_menu_title
                  sub_menu_url_destination
                }
                pixel_length
                title
                url_destination
              }
            }
            logged_in_menu_items {
              title
              url_destination
            }
            social_links {
              platform
              url_destination
            }
          }
        }
      }
    `;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      if (data.data.all_website_layout.items[0]) {
        return {
          success: true,
          data: data.data.all_website_layout.items[0]
        };
      } else {
        throw new Error("Missing configs in contentstack");
      }
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getStateAgeVerification(isPreview) {
    const query = `
    query StateAgeVerification {
      all_state_age_verification {
        items {
          background_imageConnection {
            edges {
              node {
                dimension {
                  height
                  width
                }
                url
              }
            }
          }
          exit_link_text
          header
          state_config {
            button_text
            display_name
            exit_url
            header
            legal_subtext
          }
          title
          titles
        }
      }
    }`;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(
      ({ data }) => {
        if (data.data.all_state_age_verification.items[0]) {
          return {
            success: true,
            data: data.data.all_state_age_verification.items[0]
          };
        }
        throw new Error("Missing all_state_age_verification entry");
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getEcommerceTextBanner(stateAbbr, dispensarySlug, isPreview) {
    const query = `
      query EcommerceTextBanner($dispensarySlug: String, $stateAbbr: String) {
        all_ecommerce_text_banner(
          where: {
            OR: [
              { state: { states: { abbreviation: $stateAbbr } } }
              { dispensary: { dispensary_config: { slug: $dispensarySlug } } }
            ]
          }
        ) {
          items {
            dispensaryConnection {
              edges {
                node {
                  ... on DispensaryConfig {
                    slug
                  }
                }
              }
            }
            scheduled_text_banner {
              banner_text
              scheduled_end
              scheduled_start
            }
          }
        }
      }
    `;
    return axios6.post(
      this.generateRequestUrl(!!isPreview),
      {
        query,
        variables: {
          stateAbbr,
          dispensarySlug
        }
      },
      {
        headers: this.generateRequestHeaders(!!isPreview)
      }
    ).then(({ data }) => {
      return {
        success: true,
        data: data.data.all_ecommerce_text_banner.items
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getAppNationalContent(isPreview) {
    const query = `query AppNationalContent {
      all_app_content {
        items {
          home_logged_out {
            title
            description
            bannerConnection {
              edges {
                node {
                  url
                  content_type
                }
              }
            }
          }
          home_logged_in {
            bannerConnection {
              edges {
                node {
                  url
                  content_type
                }
              }
            }
            description
            title
          }
          dispensary_default_imageConnection {
              edges {
                node {
                  content_type
                  url
                }
              }
            }
          category_default_imageConnection {
              edges {
                  node {
                      url
                  }
              }
          }
            marketing_dialogs {
              title
              description
              imageConnection {
                edges {
                  node {
                    url
                    content_type
                  }
                }
              }
            }
        }
      }
    }`;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      if (data.data.all_app_content.items[0]) {
        return {
          success: true,
          data: data.data.all_app_content.items[0]
        };
      }
      throw new Error("Missing content in AppNationalContent");
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getContentPageMetadata(slug, isPreview) {
    const typeNameQuery = {
      curaleaf: CuraleafSeoQuery
    };
    return axios6.post(
      this.generateRequestUrl(!!isPreview),
      {
        query: typeNameQuery[process.env.BRAND],
        variables: { url: slug }
      },
      {
        headers: this.generateRequestHeaders(!!isPreview)
      }
    ).then(({ data }) => {
      if (data.data.all_curaleaf_page.items[0]?.seo) {
        return {
          success: true,
          data: data.data.all_curaleaf_page.items[0].seo
        };
      }
      throw new Error("Missing seo content");
    }).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  getContentPageWebsiteData(isPreview) {
    return axios6.post(
      this.generateRequestUrl(!!isPreview),
      {
        query: ContentPageWebsiteQuery
      },
      {
        headers: this.generateRequestHeaders(!!isPreview)
      }
    ).then(({ data }) => {
      if (data.data.all_website.items[0]) {
        return {
          success: true,
          data: data.data.all_website.items[0]
        };
      }
      throw new Error("Missing content for website");
    }).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  getContentPageComponents(slug, isPreview) {
    const typeNameQuery = {
      curaleaf: CuraleafPageComponentsTypeNameQuery
    };
    return axios6.post(
      this.generateRequestUrl(!!isPreview),
      {
        query: typeNameQuery[process.env.BRAND],
        variables: { url: slug }
      },
      {
        headers: this.generateRequestHeaders(!!isPreview)
      }
    ).then(({ data }) => {
      const brand = process.env.BRAND;
      const brandQueries = {
        curaleaf: buildDynamicCuraleafPageQuery
      };
      let pageQuery;
      if (brand && brand in brandQueries) {
        if (!data.data.all_curaleaf_page.items[0]) {
          throw new Error("This page does not exist.");
        }
        const modularBlocks = data.data.all_curaleaf_page.items[0].modular_blocks;
        const pageQueryFunction = brandQueries[brand];
        pageQuery = pageQueryFunction(modularBlocks);
      } else {
        throw new Error("Invalid or missing brand env.");
      }
      return axios6.post(
        this.generateRequestUrl(!!isPreview),
        {
          query: pageQuery,
          variables: { url: slug }
        },
        {
          headers: this.generateRequestHeaders(!!isPreview)
        }
      ).then(({ data: data2 }) => {
        return {
          success: true,
          data: data2.data
        };
      }).catch((err) => {
        return {
          success: false,
          errorMessage: err
        };
      });
    }).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async favoredBrandOrder(isPreview) {
    const query = `
      query FavoredBrands {
        all_ecommerce_config {
          items {
            favored_brands {
              brand_name
              slug
              iconConnection {
                edges {
                  node {
                    url
                    dimension {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(
      ({ data }) => {
        if (data.data.all_ecommerce_config.items[0]) {
          return {
            success: true,
            data: data.data.all_ecommerce_config.items[0]
          };
        }
        throw new Error("Missing all_ecommerce_config content");
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getHomePageProductList(isPreview) {
    const query = `
      query HomePageProductList {
        all_ecommerce_config {
          items {
            favored_brands {
              brand_name
            }
          }
        }
        all_curaleaf_page(where: { url: "/" }, limit: 1) {
          items {
            modular_blocks {
              ... on CuraleafPageModularBlocksProductListing {
                __typename
                product_listing {
                  category
                  heading
                }
              }
            }
          }
        }
      }
    `;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(
      ({
        data
      }) => {
        if (data.data.all_ecommerce_config.items[0]) {
          return {
            success: true,
            data: data.data
          };
        }
        throw new Error("Missing all_ecommerce_config content");
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getStorefrontBanners2(stateAbbr, dispensarySlug) {
    const query = `
      ${imageFragment}
      query StorefrontBanners2($stateAbbr: String, $dispensarySlug: String) {
        all_storefront_banner_2(
          where: {
            OR: [
              { state: { states: { abbreviation: $stateAbbr } } }
              { dispensary: { dispensary_config: { slug: $dispensarySlug } } }
            ]
          }
        ) {
          items {
            banners {
              ... on StorefrontBanner2BannersImageBlock {
                __typename
                image_block {
                  cta_action {
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToAccount {
                      __typename
                      go_to_account {
                        location
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToCategory {
                      __typename
                      go_to_category {
                        category_name
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToBrandCategory {
                      __typename
                      go_to_brand_category {
                        brand_name
                        category_name
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToSearch {
                      __typename
                      go_to_search {
                        search_text
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToBrand {
                      __typename
                      go_to_brand {
                        brand_name
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToUrl {
                      __typename
                      go_to_url {
                        url
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionOpenStoreDrawer {
                      __typename
                      open_store_drawer {
                        mode
                      }
                    }
                  }
                  desktop_imageConnection {
                    ...imageFragment
                  }
                  end_time
                  mobile_imageConnection {
                    ...imageFragment
                  }
                  rank
                  start_time
                  where_to_show
                }
              }
            }
          }
        }
      }
    `;
    return axios6.post(
      `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
      {
        query,
        variables: { stateAbbr, dispensarySlug }
      },
      {
        headers: { access_token: this.token }
      }
    ).then(
      ({ data }) => {
        return {
          success: true,
          data: data.data.all_storefront_banner_2.items
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async getDefaultOrderConfirmation(isPreview = false) {
    const query = `
      query OrderConfirmation {
        all_ecommerce_config {
          items {
            default_order_confirmation_text
            kiosk_order_confirmation_text
            statetypeoverride {
              order_confirmation_text
              order_type
              med_rec
              stateConnection {
                edges {
                  node {
                    ... on States {
                      abbreviation
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      if (data.data.all_ecommerce_config.items[0]) {
        return {
          success: true,
          data: data.data.all_ecommerce_config.items[0]
        };
      }
      throw new Error("Missing DefaultOrderConfirmation config");
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getSecurityRules() {
    const query = `
      query SecurityRules {
        all_security_rules {
          items {
            blocked_numbers
            blocked_prefixes
            blocked_suffixes
          }
        }
      }
    `;
    return axios6.post(
      `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
      {
        query
      },
      {
        headers: { access_token: this.token }
      }
    ).then(({ data }) => {
      return {
        success: true,
        data: data.data.all_security_rules.items
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getAbandonedCartConfigs() {
    const query = `query AbandonedCartsConfigQuery {
        all_abandoned_cart_configs {
          items {
            title
            carts_per_query
            max_abandonment_duration
            min_abandonment_duration
          }
        }
      }
    `;
    return axios6.post(
      `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
      {
        query
      },
      {
        headers: { access_token: this.token }
      }
    ).then(({ data }) => {
      if (data.data.all_abandoned_cart_configs.items[0]) {
        return {
          success: true,
          data: data.data.all_abandoned_cart_configs.items[0]
        };
      } else {
        throw new Error("Missing configs in contentstack");
      }
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getAccountPreferenceForm(isPreview = false) {
    const query = `
      query AccountLoyaltyPrefs {
        all_account_loyalty_preferences {
          items {
            best_buds_tier_interactions {
              interaction_type_external_reference
              tier_key
            }
            crm_preferences_page {
              brand_preferences {
                name
              }
              category_preferences {
                name
              }
              copy {
                brands
                product_categories
              }
              statesConnection {
                edges {
                  node {
                    ... on States {
                      title
                      abbreviation
                    }
                  }
                }
              }
              top_hero_bannerConnection {
                edges {
                  node {
                    ... on HeroBanner {
                      title
                      anchor
                    }
                  }
                }
              }
            }
            title
            subtext
            refer_a_friend {
              heading
              instructions {
                disclaimer
                steps
                title
              }
              tagline
            }
            subtext
            title
            disclaimer
          }
        }
      }`;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(
      ({ data }) => {
        if (data.data.all_account_loyalty_preferences.items[0]) {
          return {
            success: true,
            data: data.data.all_account_loyalty_preferences.items[0]
          };
        }
        throw new Error("Missing all_account_loyalty_preferences content");
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getArticles(category, isPreview) {
    const pageSize = 100;
    const origArticles = [];
    const articleQuery = (str) => {
      return `
        ${imageFragment}
        query AllArticles  (
          $category: String!
        ) {
          all_article(${str} where: {category: $category}) {
            items {
              article_card {
                thumbnailConnection {
                  ...imageFragment
                }
              }
              author
              category
              published_date
              slug
              title
            }
          }
        }
      `;
    };
    const firstRequest = await axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query: articleQuery(`limit: ${pageSize}, skip: 0,`),
        variables: {
          category
        }
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    );
    const total = firstRequest.data.data.all_article.total;
    origArticles.push(...firstRequest.data.data.all_article.items);
    const callCount = Math.ceil(total / pageSize);
    for (let i = 1; i < callCount; i++) {
      const newReq = await axios6.post(
        `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
        {
          query: articleQuery(`limit: ${pageSize}, skip: ${i * pageSize},`),
          variables: {
            category
          }
        },
        {
          headers: { access_token: this.token }
        }
      );
      origArticles.push(...newReq.data.data.all_article.items);
    }
    const sorted = origArticles.sort((a, b) => {
      if (a.published_date > b.published_date) {
        return -1;
      } else if (a.published_date < b.published_date) {
        return 1;
      } else return 0;
    });
    return {
      success: true,
      data: sorted
    };
  }
  getArticleBySlug(slug, isPreview) {
    const query = `
      ${imageFragment}
      query ArticleBySlug($slug: String!) {
        all_article(where: { slug: $slug }) {
          items {
            seo {
              description
            }
            article_card {
              thumbnailConnection {
                ...imageFragment
              }
            }
            author
            category
            page {
              ... on ArticlePageImageBlock {
                __typename
                image_block {
                  full_width
                  imageConnection {
                    ...imageFragment
                  }
                }
              }
              ... on ArticlePageArticleHeader {
                __typename
                article_header {
                  reading_length
                }
              }
              ... on ArticlePageTextBlock {
                __typename
                text_block {
                  content
                }
              }
              ... on ArticlePageRelatedArticles {
                __typename
                related_articles {
                  articlesConnection(limit: 3) {
                    edges {
                      node {
                        ... on Article {
                          article_card {
                            thumbnailConnection {
                              ...imageFragment
                            }
                          }
                          title
                          category
                          author
                          published_date
                          slug
                        }
                      }
                    }
                  }
                  background
                  heading
                }
              }
            }
            published_date
            slug
            title
          }
        }
      }
    `;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query,
        variables: { slug }
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      if (data.data.all_article.items[0]) {
        return {
          success: true,
          data: data.data.all_article.items[0]
        };
      }
      throw new Error(`Article does not exist for ${slug}`);
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getNews(isPreview) {
    const pageSize = 100;
    const origNews = [];
    const newsQuery = (str) => `
      ${imageFragment}
      query News {
        all_news_item ${str} {
          items {
            imageConnection {
              ...imageFragment
            }
            published_date
            publisher
            title
            website_url
          }
          total
        }
      }
    `;
    const firstRequest = await axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query: newsQuery(`(limit: ${pageSize}, skip: 0)`)
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    );
    const total = firstRequest.data.data.all_news_item.total;
    origNews.push(...firstRequest.data.data.all_news_item.items);
    const callCount = Math.ceil(total / pageSize);
    for (let i = 1; i < callCount; i++) {
      const newReq = await axios6.post(
        `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
        {
          query: newsQuery(`(limit: ${pageSize}, skip: ${i * pageSize})`)
        },
        {
          headers: { access_token: this.token }
        }
      );
      origNews.push(...newReq.data.data.all_news_item.items);
    }
    const sorted = origNews.sort((a, b) => {
      if (a.published_date > b.published_date) {
        return -1;
      } else if (a.published_date < b.published_date) {
        return 1;
      } else return 0;
    });
    return {
      success: true,
      data: sorted
    };
  }
  fetchDispensaries = async (type, params, filter, isPreview, forWeb) => {
    let query = DispensariesQuery(filter, forWeb);
    if (type === "location") {
      query = StoreTypeQuery(filter);
    }
    if (type === "order-history") {
      query = OrderHistoryDispensariesQuery(filter);
    }
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query,
        variables: params
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      return {
        success: true,
        data: data.data
      };
    }).catch((err) => {
      return {
        success: false,
        errorMessage: err.response?.data?.errors
      };
    });
  };
  async getLocationsPageStatesData(isPreview = false, hideStateSite = false) {
    const filters = hideStateSite ? '(limit: 100, skip: 0, where: { abbreviation_nin: ["CT", "ND"] })' : "(limit: 100, skip: 0)";
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query: LocationsPageStateQuery(filters)
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      return {
        success: true,
        data: data.data.all_states.items.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          } else if (a.title > b.title) {
            return 1;
          } else return 0;
        })
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getCareerData(isPreview) {
    const jobsReq = await axios6(
      "https://boards-api.greenhouse.io/v1/boards/curaleaf/departments"
    );
    const statesReq = await this.getStatesData(isPreview, true);
    const departments = jobsReq.data.departments.map((department) => ({
      name: department.name,
      jobs: department.jobs
    })).filter((department) => department.jobs.length);
    if (statesReq.success && departments && Object.keys(departments.length)) {
      const departmentJobs = getDepartmentJobs(departments, statesReq.data);
      const career = getJobsByStates(statesReq.data, departmentJobs);
      return {
        success: true,
        data: career
      };
    }
    return {
      success: false,
      errorMessage: ""
    };
  }
  getDecisionTreeData = async (decision_tree_start, isPreview) => {
    const allQuestions = await this.getDecisionTreesQuestionByTag(
      decision_tree_start.tag,
      isPreview
    );
    if (allQuestions.success && allQuestions.data) {
      const startingQuestion = allQuestions.data.find(
        (question) => question.system.uid === decision_tree_start.decision_treeConnection.edges[0].node.system.uid
      );
      if (startingQuestion) {
        const startingQuestionMapped = {
          answers: startingQuestion.answersConnection.edges,
          question: startingQuestion.question
        };
        return updateMatchingQuestion(
          startingQuestionMapped,
          0,
          allQuestions.data
        );
      }
    }
    return null;
  };
  async getDecisionTreesQuestionByTag(tag, isPreview) {
    const query = `
      query DecisionTreeQuestions ( $tag: String! ){
        all_decision_tree_questions(where: {tags_in: $tag}) {
          items {
            answersConnection {
              edges {
                node {
                  ... on DecisionTreeAnswer {
                    text
                    questionConnection {
                      edges {
                        node {
                          ... on DecisionTreeQuestions {
                            system {
                              uid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            question
            system {
              uid
            }
          }
        }
      }`;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query,
        variables: { tag }
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(
      ({
        data
      }) => {
        return {
          success: true,
          data: data.data.all_decision_tree_questions.items
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getSubmitFormData(crmTitle = "", dispensaryUid = "", formUid = "", stateTitle = "", isPreview = false) {
    const query = `
      query SubmitForm ($crmTitle: String, $dispensaryUid: String, $formUid: String, $stateTitle: String) {
        all_crm_cdp(where: {title: $crmTitle}) {
          items {
            braze {
              api_key
              app_key
              instance_url
            }
          }
        }
        all_dispensary_config(where: {unique_id: $dispensaryUid}) {
          items {
            ll_api_key
            auto_syncConnection {
              edges {
                node {
                  ... on Dispensarysync {
                    city
                  }
                }
              }
            }
          }
        }
        all_Embeddable_Form(where: {uid: $formUid}) {
          items {
            braze_custom_event_name
            contact_us {
              contact_us_email_destination
              subject_line
              submit_to_zendesk
            }
            target_crm_cdpConnection {
              edges {
                node {
                  ... on CrmCdp {
                    braze {
                      api_key
                      app_key
                      instance_url
                    }
                  }
                }
              }
            }
            type
          }
        }
        all_states(where: {title: $stateTitle}) {
          items {
            spring_big_auth_token
          }
        }
      }
    `;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query,
        variables: { crmTitle, dispensaryUid, formUid, stateTitle }
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(
      ({
        data
      }) => {
        if (data.data) {
          return {
            success: true,
            data: {
              braze: data.data.all_crm_cdp.items[0]?.braze,
              dispensary: data.data.all_dispensary_config.items[0],
              formConfig: data.data.all_Embeddable_Form.items[0],
              springbigToken: data.data.all_states.items[0]?.spring_big_auth_token
            }
          };
        }
        throw new Error(
          "Missing CRMCDP, Dispensary, Embeddable Form Config, or State"
        );
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getEmbeddableForm(uid, isPreview) {
    const query = `
    ${imageFragment}

    query EmbeddedForms ( $uid: String! ) {
      Embeddable_Form(uid: $uid) {
        braze_custom_event_name
        confirmation {
          content
          imageConnection {
            ...imageFragment
          }
        }
        contact_us {
          submit_to_zendesk
          contact_us_email_destination
          subject_line
        }
        dispensariesConnection {
          edges {
            node {
              ... on DispensaryConfig {
                title
                slug
              }
            }
          }
        }
        dropdowntype
        form_title
        imageConnection {
          ...imageFragment
        }
        statesConnection {
          edges {
            node {
              ... on States {
                slug
                title
              }
            }
          }
        }
        subtitle
        system {
          uid
        }
        target_crm_cdpConnection {
          edges {
            node {
              ... on CrmCdp {
                title
                braze {
                  api_key
                  app_key
                  instance_url
                }
              }
            }
          }
        }
        title
        type
      }
    }`;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query,
        variables: { uid }
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      return {
        success: true,
        data: data.data.Embeddable_Form
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  // get first valid dispensary (non prerelease, non external) for users who do not have geolocation or ip for auto selected store
  async getDefaultDispensary(isPreview) {
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query: DefaultDispensaryQuery
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      return {
        success: true,
        data: data.data?.all_dispensary_config?.items[0]?.unique_id ? data.data.all_dispensary_config.items[0].unique_id : void 0
      };
    }).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async getQuestionBlockEmailInfo(uid, isPreview) {
    const query = `
    query QuestionBlock {
      all_question_block {
        items {
          email_destination
          email_subject
        }
      }
    }
    `;
    return axios6.post(
      this.generateRequestUrl(isPreview),
      {
        query,
        variables: { uid }
      },
      {
        headers: this.generateRequestHeaders(isPreview)
      }
    ).then(({ data }) => {
      return {
        success: true,
        data: data.data.all_question_block.items
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getDispensariesForOrderHistory() {
    const pageSize = 100;
    const origDispensaries = [];
    const filters = `(limit: ${pageSize}, skip: 0)`;
    const isPreview = false;
    const firstRequest = await this.fetchDispensaries(
      "order-history",
      {},
      filters,
      isPreview
    );
    if (firstRequest.success) {
      const total = firstRequest.data.all_dispensary_config?.total;
      origDispensaries.push(...firstRequest.data.all_dispensary_config.items);
      const callCount = Math.ceil(total / pageSize);
      if (total > pageSize) {
        for (let i = 1; i < callCount; i++) {
          const newFilters = `(limit: ${pageSize}, skip: ${i * pageSize})`;
          const newReq = await this.fetchDispensaries(
            "order-history",
            {},
            newFilters,
            isPreview
          );
          if (newReq.success) {
            origDispensaries.push(...newReq.data.all_dispensary_config.items);
          }
        }
      }
      return {
        success: true,
        data: origDispensaries
      };
    }
    return {
      success: false,
      errorMessage: ""
    };
  }
};

// apis/google/ipToGeolocation.ts
import axios7 from "axios";
var GoogleGeoIp = class {
  static API_KEY = process.env.NEXT_PUBLIC_GCP_API_KEY || "";
  static async getGoogleCoords() {
    if (!this.API_KEY) {
      return {
        location: {
          lat: void 0,
          lng: void 0
        }
      };
    }
    const locationReq = await axios7.post(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=${this.API_KEY}`
    );
    return locationReq.data;
  }
};

// apis/auth/websiteFunctions.ts
import axios8 from "axios";
import Cookies2 from "js-cookie";

// apis/auth/srp.ts
import crypto from "crypto";
import { BigInteger } from "jsbn";
import CryptoJS from "crypto-js";
var default_params = {
  N_length_bits: 4096,
  N_str: `
FFFFFFFF FFFFFFFF C90FDAA2 2168C234 C4C6628B 80DC1CD1 29024E08
8A67CC74 020BBEA6 3B139B22 514A0879 8E3404DD EF9519B3 CD3A431B
302B0A6D F25F1437 4FE1356D 6D51C245 E485B576 625E7EC6 F44C42E9
A637ED6B 0BFF5CB6 F406B7ED EE386BFB 5A899FA5 AE9F2411 7C4B1FE6
49286651 ECE45B3D C2007CB8 A163BF05 98DA4836 1C55D39A 69163FA8
FD24CF5F 83655D23 DCA3AD96 1C62F356 208552BB 9ED52907 7096966D
670C354E 4ABC9804 F1746C08 CA18217C 32905E46 2E36CE3B E39E772C
180E8603 9B2783A2 EC07A28F B5C55DF0 6F4C52C9 DE2BCBF6 95581718
3995497C EA956AE5 15D22618 98FA0510 15728E5A 8AAAC42D AD33170D
04507A33 A85521AB DF1CBA64 ECFB8504 58DBEF0A 8AEA7157 5D060C7D
B3970F85 A6E1E4C7 ABF5AE8C DB0933D7 1E8C94E0 4A25619D CEE3D226
1AD2EE6B F12FFA06 D98A0864 D8760273 3EC86A64 521F2B18 177B200C
BBE11757 7A615D6C 770988C0 BAD946E2 08E24FA0 74E5AB31 43DB5BFC
E0FD108E 4B82D120 A9210801 1A723C12 A787E6D7 88719A10 BDBA5B26
99C32718 6AF4E23C 1A946834 B6150BDA 2583E9CA 2AD44CE8 DBBBC2DB
04DE8EF9 2E8EFC14 1FBECAA6 287C5947 4E6BC05D 99B2964F A090C3A2
233BA186 515BE7ED 1F612970 CEE2D7AF B81BDD76 2170481C D0069127
D5B05AA9 93B4EA98 8D8FDDC1 86FFB7DC 90A6C08F 4DF435C9 34063199
FFFFFFFF FFFFFFFF
`,
  g_str: "05",
  hash_alg: "sha256"
};
var SRP = class {
  n;
  g;
  n_length;
  alg;
  constructor(n = default_params.N_str, n_length = default_params.N_length_bits, g = default_params.g_str, alg = default_params.hash_alg) {
    this.n = this.hexToBigInt(n);
    this.g = this.hexToBigInt(g);
    this.n_length = n_length;
    this.alg = alg;
  }
  hexToBigInt = (s) => {
    const buf = Buffer.from(s.split(/\s|\n/).join(""), "hex");
    return new BigInteger(buf.toString("hex"), 16);
  };
  bnToBuffer(bigNumber, trimOrSize) {
    let bnByteArray = Buffer.from(bigNumber.toByteArray());
    if (trimOrSize === true && bnByteArray[0] === 0) {
      bnByteArray = bnByteArray.slice(1);
    } else if (typeof trimOrSize == "number") {
      if (bnByteArray.length > trimOrSize) {
        for (let i = 0; i < bnByteArray.length - trimOrSize; i++) {
          if (bnByteArray[i] !== 0) {
            throw "idk";
          }
        }
        return bnByteArray.slice(bnByteArray.length - trimOrSize);
      } else if (bnByteArray.length < trimOrSize) {
        const padded = Buffer.alloc(trimOrSize);
        padded.fill(0, 0, trimOrSize - bnByteArray.length);
        bnByteArray.copy(padded, trimOrSize - bnByteArray.length);
        return padded;
      }
    }
    return bnByteArray;
  }
  gen_key = async (length = 32) => {
    return await crypto.randomBytes(length);
  };
  calc_s(length = 32) {
    return this.gen_key(length);
  }
  browser_key = async (length = 32) => {
    const key = await this.gen_key(length);
    return key.toString("hex");
  };
  calc_a(length = 32) {
    return this.gen_key(length);
  }
  calc_b(length = 32) {
    return this.gen_key(length);
  }
  padTo(n) {
    const padding = this.n_length / 8 - n.length;
    const result = Buffer.alloc(this.n_length / 8);
    result.fill(0, 0, padding);
    n.copy(result, padding);
    return result;
  }
  padToN(number) {
    const n = number.toString(16).length % 2 !== 0 ? "0" + number.toString(16) : number.toString(16);
    return this.padTo(Buffer.from(n, "hex"));
  }
  calc_A = (a) => {
    const A_bn = this.g.modPow(a, this.n);
    return this.bnToBuffer(A_bn, true);
  };
  calc_x = (salt, I, P) => {
    const hashIP = crypto.createHash(this.alg).update(Buffer.concat([I, Buffer.from(":"), P])).digest();
    const hashX = crypto.createHash(this.alg).update(salt).update(hashIP).digest();
    return new BigInteger(hashX.toString("hex"), 16);
  };
  browser_x = (s, I, P) => {
    const SHA = CryptoJS[this.alg.toUpperCase()];
    const IP = `${I}:${P}`;
    const IPHash = SHA(IP);
    const salt = CryptoJS.enc.Hex.parse(s);
    const M = salt.concat(IPHash);
    const x = SHA(M);
    return new BigInteger(x.toString(CryptoJS.enc.Hex), 16);
  };
  calc_v = (salt, I, P) => {
    const x = this.calc_x(salt, I, P);
    const v_num = this.g.modPow(x, this.n);
    return v_num.toString(16);
  };
  browser_v = (salt, I, P) => {
    const x = this.browser_x(salt, I.toLowerCase(), P);
    const v_num = this.g.modPow(x, this.n);
    return v_num.toString(16);
  };
  calc_B = (k, v, b) => {
    const r = k.multiply(v).add(this.g.modPow(b, this.n)).mod(this.n);
    return this.bnToBuffer(r, true);
  };
  calc_u = (A, B) => {
    const u_buf = crypto.createHash(this.alg).update(this.padTo(A)).update(this.padTo(B)).digest();
    return new BigInteger(u_buf.toString("hex"), 16);
  };
  browser_u = (A, B) => {
    const SHA = CryptoJS[this.alg.toUpperCase()];
    return SHA(CryptoJS.enc.Hex.parse(A + B)).toString(CryptoJS.enc.Hex);
  };
  calc_k = () => {
    const k_buf = crypto.createHash(this.alg).update(this.padToN(this.n)).update(this.padToN(this.g)).digest();
    return new BigInteger(k_buf.toString("hex"), 16);
  };
  browser_k = () => {
    const SHA = CryptoJS[this.alg.toUpperCase()];
    const n = this.padToN(this.n).toString("hex");
    const g = this.padToN(this.g).toString("hex");
    const hashNG = SHA(CryptoJS.enc.Hex.parse(n + g)).toString(
      CryptoJS.enc.Hex
    );
    return new BigInteger(hashNG, 16);
  };
  calc_server_S = (v, A, b, u) => {
    const S_num = A.multiply(v.modPow(u, this.n)).modPow(b, this.n).mod(this.n);
    return this.bnToBuffer(S_num, true);
  };
  calc_client_S = (k, x, a, B, u) => {
    const S_num = B.subtract(k.multiply(this.g.modPow(x, this.n))).modPow(a.add(u.multiply(x)), this.n).mod(this.n);
    return this.bnToBuffer(S_num, true);
  };
  hashBuffer = (buf) => {
    const SHA = CryptoJS[this.alg.toUpperCase()];
    const hash1 = SHA(buf).concat(CryptoJS.lib.WordArray.create([0, 0, 0, 0])).toString(CryptoJS.enc.Hex);
    const hash2 = SHA(buf).concat(CryptoJS.lib.WordArray.create([0, 0, 0, 1])).toString(CryptoJS.enc.Hex);
    return Buffer.concat([
      Buffer.from(hash1, "hex"),
      Buffer.from(hash2, "hex")
    ]);
  };
  calc_M1 = (A, B, S) => {
    return crypto.createHash(this.alg).update(A).update(B).update(S).digest();
  };
  browser_M1 = (A, B, S) => {
    const SHA = CryptoJS[this.alg.toUpperCase()];
    const hash = SHA(
      CryptoJS.enc.Hex.parse(A).concat(CryptoJS.enc.Hex.parse(B)).concat(CryptoJS.enc.Hex.parse(S))
    );
    return hash.toString(CryptoJS.enc.Hex);
  };
  calc_server_K = (v, A, b, u) => {
    const S = this.calc_server_S(v, A, b, u);
    return {
      S,
      K: this.hashBuffer(S)
    };
  };
  calc_client_K = (k, x, a, B, u) => {
    const S = this.calc_client_S(k, x, a, B, u);
    return {
      S,
      K: this.hashBuffer(S)
    };
  };
  calc_browser_K = (k, x, a, B, u) => {
    const S = this.calc_client_S(k, x, a, B, u);
    return {
      S,
      K: this.hashBuffer(S).toString("hex")
    };
  };
  client_register = async (i, p) => {
    const salt = await this.browser_key();
    const verifier = await this.browser_v(salt, i, p);
    return {
      salt,
      verifier
    };
  };
  client_login_A = async () => {
    const a = await this.browser_key();
    return {
      a,
      A: this.calc_A(this.hexToBigInt(a))
    };
  };
  client_login_session = async (A, B, s, I, P, a) => {
    const u = this.browser_u(A, B);
    const x = this.browser_x(s, I, P);
    const k = this.browser_k();
    const K = this.calc_browser_K(
      k,
      x,
      this.hexToBigInt(a),
      this.hexToBigInt(B),
      this.hexToBigInt(u)
    );
    const M1 = this.browser_M1(A, B, K.S.toString("hex"));
    return {
      K: K.K,
      S: K.S,
      M1
    };
  };
};

// queries/curaql.ts
var curaql_exports = {};
__export(curaql_exports, {
  ADD_ITEM_TO_CART: () => ADD_ITEM_TO_CART,
  CART_META_KEY: () => CART_META_KEY,
  CLEAR_USER_CART: () => CLEAR_USER_CART,
  GET_CART_DATA: () => GET_CART_DATA,
  GET_CART_RECOMMENDATIONS: () => GET_CART_RECOMMENDATIONS,
  GET_LOYALTY_DATA: () => GET_LOYALTY_DATA,
  GET_LOYALTY_POINT_DATA: () => GET_LOYALTY_POINT_DATA,
  GET_ORDER_HISTORY: () => GET_ORDER_HISTORY,
  GET_USER_CART: () => GET_USER_CART,
  REMOVE_ITEM_FROM_USER_CART: () => REMOVE_ITEM_FROM_USER_CART,
  UPDATE_ORDER_TYPE: () => UPDATE_ORDER_TYPE,
  UPDATE_USER: () => UPDATE_USER,
  UPDATE_USER_CART: () => UPDATE_USER_CART
});

// queries/curaql.fragments.ts
var TILE_PRODUCT = `
  fragment tile on Product {
    brand {
      name
    }
    category {
      displayName
    }
    cardDescription
    id
    images {
      url
    }
    name
    offers {
      id
      title
    }
    strain {
      key
    }
    subcategory {
      displayName
    }
    variants {
      id
      isSpecial
      option
      price
      quantity
      specialPrice
    }
  }
`;
var CART_PRODUCT = `
  fragment cart on Product {
    brand {
      name
    }
    category {
      displayName
      key
    }
    effects {
      displayName
      key
    }
    id
    images {
      url
    }
    name
    offers {
      id
      title
    }
    strain {
      key
    }
    subcategory {
      displayName
      key
    }
    variants {
      id
      isSpecial
      option
      price
      quantity
      specialPrice
    }
  }
`;
var GET_USER_CART = `
  ${CART_PRODUCT}
  query UserCart($dispensaryUniqueId: String, $checkoutId: String) {
    cart(dispensaryUniqueId: $dispensaryUniqueId, checkoutId: $checkoutId) {
      dispensaryUniqueId
      address {
        city
        deliverable
        formatted
        geometry {
          coordinates
          type
        }
        state
        street1
        street2
        valid
        zip
      }
      id
      items {
        id
        option
        product {
          ...cart
        }
        productId
        quantity
        valid
        isDiscounted
        basePrice
        discounts {
          total
        }
        taxes {
          total
          cannabis
          sales
        }
      }
      orderType
      priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
      }
      pricingType
      redirectUrl
      updatedAt
    }
  }
`;
var REMOVE_ITEM_FROM_USER_CART = `
  ${CART_PRODUCT}
  mutation RemoveItemFromCart(
    $dispensaryUniqueId: ID!
    $checkoutId: ID!
    $itemId: ID!
  ) {
    removeItemFromCart(
      dispensaryUniqueId: $dispensaryUniqueId
      checkoutId: $checkoutId
      itemId: $itemId
    ) {
      dispensaryUniqueId
      address {
        city
        deliverable
        formatted
        geometry {
          coordinates
          type
        }
        state
        street1
        street2
        valid
        zip
      }
      id
      items {
        id
        option
        product {
          ...cart
        }
        productId
        quantity
        valid
        isDiscounted
        basePrice
        discounts {
          total
        }
        taxes {
          total
          cannabis
          sales
        }
      }
      orderType
      priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
      }
      pricingType
      redirectUrl
      updatedAt
    }
  }
`;
var ADD_ITEM_TO_CART = `
  ${CART_PRODUCT}
  mutation AddItemFromCart(
    $dispensaryUniqueId: ID!
    $orderType: OrderType!
    $pricingType: PricingType!
    $productId: ID!
    $quantity: Int!
    $option: String!
    $checkoutId: ID
    $baseCartUrl: String
    $metadata: JSON
  ) {
    addItemToCart(
      dispensaryUniqueId: $dispensaryUniqueId
      orderType: $orderType
      pricingType: $pricingType
      productId: $productId
      quantity: $quantity
      option: $option
      checkoutId: $checkoutId
      baseCartUrl: $baseCartUrl
      metadata: $metadata
    ) {
      id
      priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
      }
      pricingType
      redirectUrl
      orderType
      items {
        id
        option
        product {
          ...cart
        }
        quantity
        valid
        isDiscounted
        basePrice
        discounts {
          total
        }
        taxes {
          total
          cannabis
          sales
        }
        productId
      }
      updatedAt
    }
  }
`;
var UPDATE_USER_CART = `
  ${CART_PRODUCT}
  mutation UpdateUserCart(
    $dispensaryUniqueId: ID!
    $orderType: OrderType!
    $pricingType: PricingType!
    $checkoutId: ID!
  ) {
    mergeCart(
      dispensaryUniqueId: $dispensaryUniqueId
      orderType: $orderType
      pricingType: $pricingType
      checkoutId: $checkoutId
    ) {
      id
      priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
      }
      pricingType
      redirectUrl
      orderType
      items {
        id
        option
        product {
          ...cart
        }
        quantity
        valid
        isDiscounted
        basePrice
        discounts {
          total
        }
        taxes {
          total
          cannabis
          sales
        }
        productId
      }
      updatedAt
    }
  }
`;
var CLEAR_USER_CART = `
  mutation ClearUserCart {
    clearCart {
      success
    }
  }
`;
var UPDATE_ORDER_TYPE = `
  ${CART_PRODUCT}
  mutation UpdateOrderType(
    $dispensaryUniqueId: ID!
    $checkoutId: ID!
    $orderType: OrderType!
    $pricingType: PricingType!
    $metadata: JSON
  ) {
    updateOrderType(
      dispensaryUniqueId: $dispensaryUniqueId
      checkoutId: $checkoutId
      orderType: $orderType
      pricingType: $pricingType
      metadata: $metadata
    ) {
      id
      items {
        id
        option
        product {
          ...cart
        }
        productId
        quantity
        valid
        isDiscounted
        basePrice
        discounts {
          total
        }
        taxes {
          total
          cannabis
          sales
        }
      }
      orderType
      priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
      }
      pricingType
      redirectUrl
    }
  }
`;
var GET_CART_RECOMMENDATIONS = `
  ${TILE_PRODUCT}
  query CartRecommendation($dispensaryUniqueId: ID!, $checkoutId: ID) {
    recommendations(
      dispensaryUniqueId: $dispensaryUniqueId
      checkoutId: $checkoutId
    ) {
      ...tile
    }
  }
`;
var UPDATE_USER = `
  mutation UpdateUser(
    $firstName: String
    $lastName: String
    $phone: String
    $birthYear: String
    $birthMonth: String
    $birthDay: String
    $state: String
    $brandPreference: [String]
    $categoryPreference: [String]
    $dispensaryPreferences: [String]
    $mainSubscriptionStatus: SubscriptionStatus
    $smsSubscriptionStatus: Boolean
  ) {
    user(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      birthYear: $birthYear
      birthMonth: $birthMonth
      birthDay: $birthDay
      state: $state
      brandPreference: $brandPreference
      categoryPreference: $categoryPreference
      dispensaryPreferences: $dispensaryPreferences
      mainSubscriptionStatus: $mainSubscriptionStatus
      smsSubscriptionStatus: $smsSubscriptionStatus
    ) {
      birthday
      brandPreference
      categoryPreference
      dispensaryPreferences
      email
      firstName
      lastName
      mainSubscriptionStatus
      smsSubscriptionStatus
      phone
      state
    }
  }
`;
var GET_LOYALTY_DATA = `
  query LoyaltyMemberInfo {
    loyaltyMemberInfo {
      currentTier {
        name
        formattedName
        prestige
        perks {
          title
          description
          iconName
        }
        earningInfo {
          bestBudsBonus
        }
      }
      nextTier {
        name
        formattedName
        prestige
        perks {
          title
          description
          iconName
        }
      }
      incentiveText
      isMaxRank
      nextTierSpend
      tierProgress
      totalPointsAvailable
      totalPointsDeducted
      totalPointsExpired
      totalPointsUsed
      totalSpend
    }
  }
`;
var GET_LOYALTY_POINT_DATA = `
  query LoyaltyPointHistory {
    loyaltyPointHistory {
      date
      locationName
      pointsEarned
      pointsEarnedFormatted
      retailValue
      retailValueFormatted
    }
  }
`;
var GET_CART_DATA = `
  query RedirectParamUrl {
    user {
      redirectParamUrl
    }
  }
`;
var GET_ORDER_HISTORY = `
 query Items($limit: Int, $offset: Int) {
  orderHistory(limit: $limit, offset: $offset) {
    finalTotal
    items {
      brand
      discount
      size
      itemTotalPrice
      itemUnitPrice
      lineage
      name
      onlineTitle
      productId
      purchasedPrice
      imageUrl
      quantity
    }
    location
    loyaltyPts
    totalTax
    totalSales
    totalDiscount
    loyaltyUsed
    
    totalPrice
    transactionId
    transactionItemDateTime
    discountSummary {
      name
      value
    }
  }
}
`;

// queries/curaql.ts
var CART_META_KEY = "cart_meta";

// utils/datalayerAnalytics.ts
import Cookies from "js-cookie";

// utils/slugify.ts
var slugify = (str) => {
  if (!str || typeof str !== "string") return "";
  return str.trim().toLowerCase().replace("-", " ").replace("_", "-").replace(/  +/g, " ").replace(/ /g, "-");
};
var alphanumericSlugify = (slug) => {
  if (!slug || typeof slug !== "string") return "";
  return slugify(slug.replace(/[^a-zA-Z0-9 ]/g, ""));
};
var capitalize = (s) => {
  if (!s || typeof s !== "string") return "";
  const w = s.split(" ");
  return w.map((word) => {
    if (word.length) {
      word = word.toLowerCase();
      return word[0]?.toUpperCase() + word.substring(1);
    }
  }).join(" ");
};
var capitalizeFirstLetter = (str) => {
  if (!str || typeof str !== "string") return "";
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
var deslugify = (slug) => {
  if (!slug || typeof slug !== "string") return "";
  return slug.replace("-", "_").toUpperCase();
};
var dutchieFriendlyName = (str) => {
  if (!str || typeof str !== "string") return "";
  const tmp = str.toLowerCase().replace("-", " ").replace("_", " ");
  return capitalize(tmp);
};
var lowerScore = (str) => {
  if (!str || typeof str !== "string") return "";
  return str.toLowerCase().replace(" ", "_");
};
var getSlug = (slug, stateDispensarySlug) => {
  let s = "";
  if (typeof slug !== "string") {
    s = slug.slug;
  } else {
    s = slug;
  }
  if (s.startsWith("http")) {
    return s;
  }
  if (stateDispensarySlug) {
    s = s.replace("%s", stateDispensarySlug);
  }
  if (!s.startsWith("/")) {
    s = `/${slug}`;
  }
  return s;
};
var snakeCase = (str) => {
  if (!str || typeof str !== "string") return "";
  return str.toLowerCase().replace(/[\s-]/g, "_").replace(/'/g, "");
};

// utils/datalayerAnalytics.ts
var mapStoreMetadata = (dispensary) => {
  return {
    store_name: dispensary.friendlyName || "",
    store_state: dispensary.location.state.title || "",
    store_address: dispensary.location.address || "",
    store_city: dispensary.location.city || ""
  };
};
var pushEvent = (event) => {
  if (typeof window === "undefined")
    return console.error("attempted analytics without window defined!");
  const userId = Cookies.get("curaleafAccountId");
  const dlEvent = {
    site_platform: "curaleaf-web",
    site_version: process.env.NEXT_PUBLIC_WEBSITE_VERSION || "local",
    git_sha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "local",
    ...event
  };
  if (userId) {
    dlEvent.userId = userId;
  }
  if (typeof event.store_state === "string") {
    dlEvent.store_state = capitalizeFirstLetter(event.store_state);
  }
  if (window.dataLayer) {
    window.dataLayer.push({
      ecommerce: null
    });
    window.dataLayer.push(dlEvent);
  }
};
var generateAnalyticsContext = (pathname, shopLink, containingPageType, header, params) => {
  const view = window.localStorage.getItem("productView") || "list";
  const slugifyHeaderId = header ? `${header.toLowerCase().replaceAll(" ", "_").replaceAll(",", "").replace(/\.{3}/g, "")}_carousel` : "carousel";
  const headerName = header ? `${header.replace(/\.{3}/g, "")} Carousel` : "Carousel";
  let id = params ? "undefined_page" : "";
  let name = params ? "Undefined Page" : "";
  if (pathname.includes("cart")) {
    id = `cart_${slugifyHeaderId}`;
    name = `Cart ${headerName}`;
  } else if (pathname.includes("categories")) {
    if (params) {
      id = `${params.key}_category_${view}_page`;
      name = capitalize(`${params.key} category ${capitalize(view)} page`);
    } else {
      id = `category_${slugifyHeaderId}`;
      name = `Category ${headerName}`;
    }
  } else if (pathname === shopLink) {
    id = `storefront_homepage_${slugifyHeaderId}`;
    name = `Storefront Homepage ${headerName}`;
  } else if (pathname.includes("products")) {
    id = `pdp_${slugifyHeaderId}`;
    name = `PDP ${headerName}`;
  } else if (pathname.includes("special") && params) {
    id = `specials_${view}_page`;
    name = capitalize(`special ${view} page`);
  }
  return { list: { id, name, containingPageType } };
};
var mapOrderItems = (items, metadata) => {
  return items.map((item) => {
    const variant = item.product.variants.find((v) => v.option);
    return {
      item_id: variant.id,
      item_name: item.product.name,
      discount: calculateDiscount(variant),
      item_brand: item.product.brand?.name,
      item_category: dutchieFriendlyName(item.product.category),
      item_category2: item.product.subcategory ? dutchieFriendlyName(item.product.subcategory) : void 0,
      item_variant: variant.option,
      affiliation: metadata.dispensaryFriendlyName,
      price: variant.isSpecial ? variant.specialPrice || void 0 : variant.price || void 0,
      full_price: variant.price,
      quantity: item.quantity
    };
  });
};
var mapConsolidateProductToGTM = (product, dispensaryFriendlyName, context) => {
  return {
    item_id: product.id,
    item_name: product.name,
    item_brand: product.brand?.name,
    item_category: dutchieFriendlyName(product.category),
    item_category2: typeof product.subcategory === "string" ? dutchieFriendlyName(product.subcategory) : void 0,
    full_price: context?.item_variant.price,
    affiliation: dispensaryFriendlyName,
    index: context ? context.index : void 0,
    item_list_id: context ? context.item_list_id : void 0,
    item_list_name: context ? context.item_list_name : void 0,
    item_variant: context ? context.item_variant.option : void 0,
    quantity: context ? context.quantity : void 0
  };
};
var pushPageView = (params) => {
  let eventObj = {
    event: "page_view",
    page_type: params.page_type
  };
  if (params.storeMetadata) {
    eventObj = { ...eventObj, ...params.storeMetadata };
  } else if (params.consolidateDispensary) {
    eventObj = {
      ...eventObj,
      ...mapStoreMetadata(params.consolidateDispensary)
    };
  }
  return pushEvent(eventObj);
};
var pushProductView = (params) => {
  return pushEvent({
    event: "view_item",
    ecommerce: {
      currency: "USD",
      value: void 0,
      // This is undefined because pricing is at a variant level for us
      items: [
        mapConsolidateProductToGTM(
          params.product,
          params.consolidateDispensary.friendlyName
        )
      ]
    },
    ...mapStoreMetadata(params.consolidateDispensary)
  });
};
var pushAddToCart = (params) => {
  const storemeta = params.consolidateDispensary ? { ...mapStoreMetadata(params.consolidateDispensary) } : {};
  return pushEvent({
    event: "add_to_cart",
    ecommerce: {
      currency: "USD",
      value: void 0,
      // This is undefined because pricing is at a variant level for us
      items: [
        mapConsolidateProductToGTM(
          params.product,
          params.consolidateDispensary?.friendlyName || "",
          params.context
        )
      ]
    },
    ...storemeta
  });
};
var pushRemoveFromCart = (params) => {
  const storemeta = params.consolidateDispensary ? { ...mapStoreMetadata(params.consolidateDispensary) } : {};
  return pushEvent({
    event: "remove_from_cart",
    ecommerce: {
      currency: "USD",
      value: void 0,
      // This is undefined because pricing is at a variant level for us
      items: [
        mapConsolidateProductToGTM(
          params.product,
          params.consolidateDispensary?.friendlyName || "",
          params.context
        )
      ]
    },
    ...storemeta
  });
};
var pushViewCart = (params) => {
  return pushEvent({
    event: "view_cart",
    ecommerce: {
      currency: "USD",
      value: params.value,
      items: mapOrderItems(params.items, {
        dispensaryFriendlyName: params.consolidateDispensary.friendlyName
      })
    },
    ...mapStoreMetadata(params.consolidateDispensary)
  });
};
var pushAccountEvent = (params) => {
  return pushEvent({
    event: "account_event",
    action: params.action
  });
};
var pushBasicEvent = (event, params, consolidateDispensary) => {
  let eventObj = {
    event,
    ...params
  };
  if (consolidateDispensary) {
    eventObj = {
      ...eventObj,
      ...mapStoreMetadata(consolidateDispensary)
    };
  }
  return pushEvent(eventObj);
};
function calculateDiscount(productVariant) {
  return productVariant && productVariant.isSpecial && productVariant.price && productVariant.specialPrice ? productVariant.price - productVariant.specialPrice : 0;
}
var pushPurchase = ({
  value,
  transaction_id,
  coupon,
  shipping,
  tax,
  items,
  consolidateDispensary,
  subtotal,
  order_discount,
  fee
}) => {
  return pushEvent({
    event: "purchase",
    ecommerce: {
      subtotal,
      order_discount,
      value,
      transaction_id,
      coupon,
      shipping,
      tax,
      items,
      currency: "USD",
      fee
    },
    ...mapStoreMetadata(consolidateDispensary)
  });
};
var pushBeginCheckout = (params) => {
  return pushEvent({
    event: "begin_checkout",
    ecommerce: {
      currency: "USD",
      value: params.value,
      coupon: params.coupon,
      items: params.items
    },
    ...mapStoreMetadata(params.consolidateDispensary)
  });
};
var pushErrorEvent = (params) => {
  let errorEvent = {
    event: "client_error",
    error_category: params.category,
    code_location: params.location,
    // 'addToCart' | 'CTAButton',
    description: params.description,
    fatal: params.fatal || false
    // set to true if the error is fatal
  };
  if (params.consolidateDispensary) {
    errorEvent = {
      ...errorEvent,
      ...mapStoreMetadata(params.consolidateDispensary)
    };
  }
  return pushEvent(errorEvent);
};
var pushCarouselClick = ({
  page_type,
  dispensary,
  carousel_name,
  product,
  index
}) => {
  let event = {
    event: "carousel_click",
    page_type,
    // page_type of the page containing the carousel, e.g. shop/home or shop/pdp
    carousel_name,
    // e.g. 'Storefront Homepage Deals For You Carousel'
    index,
    item_name: product.name,
    ecommerce: {
      item_list_name: carousel_name,
      // e.g. 'Storefront Homepage Deals For You Carousel'
      item_list_id: carousel_name.toLowerCase().replace(" ", "_"),
      // carousel name in snake_case, e.g. 'storefront_homepage_deals_for_you_carousel'
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          affiliation: dispensary?.friendlyName || "",
          item_brand: product.brand?.name || "",
          item_category: product.category,
          item_category2: product.subcategory || "",
          item_list_name: carousel_name,
          // e.g. 'Storefront Homepage Deals For You Carousel'
          item_list_id: snakeCase(carousel_name),
          // carousel name in snake_case, e.g. 'storefront_homepage_deals_for_you_carousel'
          item_variant: product.variants[0]?.option || "",
          price: product.variants[0]?.specialPrice || "",
          index
        }
      ]
    }
  };
  const storeMetaData = dispensary ? mapStoreMetadata(dispensary) : null;
  if (storeMetaData) {
    event = {
      ...event,
      ...storeMetaData
    };
  }
  return pushEvent(event);
};
var DatalayerAnalytics = {
  calculateDiscount,
  generateAnalyticsContext,
  mapOrderItems,
  pushAccountEvent,
  pushAddToCart,
  pushBasicEvent,
  pushBeginCheckout,
  pushCarouselClick,
  pushErrorEvent,
  pushPageView,
  pushProductView,
  pushPurchase,
  pushViewCart,
  pushRemoveFromCart
};

// data/commonlyUsedPhoneNumbers.ts
var commonlyUsedPhoneNumbers = ["1111111111", "1234567890"];

// utils/fakeNumber.ts
var contentstack = new ContentStackService();
var isFakeNumber = async (number) => {
  const rules = await contentstack.getSecurityRules();
  if (rules.success && rules.data && Array.isArray(rules.data)) {
    const { blocked_numbers, blocked_prefixes, blocked_suffixes } = rules.data[0];
    const prefix = number.substring(0, 3);
    const suffix = number.substring(3);
    if (blocked_numbers.length) {
      const isBlocked = blocked_numbers.some((blockedNum) => {
        return blockedNum == number;
      });
      if (isBlocked) return isBlocked;
    }
    if (blocked_prefixes.length) {
      const isBlocked = blocked_prefixes.some((blockedPrefix) => {
        return blockedPrefix == prefix;
      });
      if (isBlocked) return isBlocked;
    }
    if (blocked_suffixes.length) {
      const isBlocked = blocked_suffixes.some((blockedSuffix) => {
        return blockedSuffix == suffix;
      });
      if (isBlocked) return isBlocked;
    }
  }
  return commonlyUsedPhoneNumbers.indexOf(number) > -1;
};

// utils/formStateDispensaryMap.ts
var formStatesNDispensariesMap = async (dropdowntype, selected) => {
  const contentstack3 = new ContentStackService();
  const allStates = await contentstack3.getStatesData(false, true);
  const allDispensaries = await contentstack3.getDispensaryData(true, false);
  switch (dropdowntype) {
    case "All States / All Dispensaries": {
      if (allStates.success && allDispensaries.success) {
        const statesNdispensaries = allStates.data.map((state) => ({
          state: state.title,
          dispensaries: allDispensaries.data.reduce(
            (arr, disp) => {
              if (disp.state.title === state.title) {
                arr.push({
                  name: disp.friendly_name
                });
              }
              return arr;
            },
            []
          )
        }));
        return {
          success: true,
          data: statesNdispensaries
        };
      }
      console.warn("Error: States or Dispensaries Req failed");
      return {
        success: false,
        errorMessage: allStates.errorMessage
      };
    }
    case "All States / No Dispensaries": {
      if (allStates.success) {
        return {
          success: true,
          data: allStates.data.map((state) => ({
            state: state.title,
            dispensaries: []
          }))
        };
      }
      console.warn("Error: States req failed");
      return {
        success: false,
        errorMessage: allStates.errorMessage
      };
    }
    case "Selected States / Selected Dispensaries": {
      if (allDispensaries.success && selected?.dispensaries.length && selected?.states.length) {
        const dispensaries = allDispensaries.data.filter((dispensary) => {
          const match = selected.dispensaries.find(
            (dispo) => dispo.node.friendly_name === dispensary.friendly_name
          );
          if (match) {
            return dispensary;
          }
        });
        const statesNdispensaries = selected.states.map((state) => {
          return {
            state: state.node.title,
            dispensaries: dispensaries.filter((disp) => disp.state.title === state.node.title).map((d) => ({
              name: d.friendly_name
            }))
          };
        });
        return {
          success: true,
          data: statesNdispensaries
        };
      }
      console.warn(
        "Error: No selected dispensaries & states for dropdowntype: Selected Dispensaries OR dispensaries req failed"
      );
      return {
        success: false,
        errorMessage: allStates.errorMessage
      };
    }
    case "Selected Dispensaries": {
      if (selected?.dispensaries.length && allStates.success && allDispensaries.success) {
        const dispensaries = allDispensaries.data.filter((dispensary) => {
          const match = selected.dispensaries.find(
            (dispo) => dispo.node.friendly_name === dispensary.friendly_name
          );
          if (match) {
            return dispensary;
          }
        });
        const states = [];
        dispensaries.forEach((disp) => {
          const matchingState = allStates.data.find(
            (state) => disp.state.title === state.title
          );
          if (matchingState) {
            if (!states.includes(matchingState.title))
              states.push(matchingState.title);
          }
        });
        const statesNdispensaries = states.map((state) => {
          return {
            state,
            dispensaries: dispensaries.filter((disp) => disp.state.title === state).map((d) => ({
              name: d.friendly_name
            }))
          };
        });
        return {
          success: true,
          data: statesNdispensaries
        };
      }
      console.warn(
        "Error: No selected dispensaries for dropdowntype: Selected Dispensaries"
      );
      return {
        success: false,
        errorMessage: allStates.errorMessage
      };
    }
    default: {
      console.warn(
        "Error: Missing dropdowntype case for Embeddable Form Map: ",
        dropdowntype
      );
      return {
        success: false,
        errorMessage: `Missing dropdown type for Embeddable Form Map: ${dropdowntype}`
      };
    }
  }
};

// utils/minHeight.ts
var getMinHeightClass = (type, isKiosk, contentstackEnv) => {
  let minHeightClass = "min-height";
  isKiosk ? minHeightClass += "-kiosk" : minHeightClass += "-web";
  type === "ecom" ? minHeightClass += "-ecom" : null;
  if (contentstackEnv) {
    const stateAbbr = contentstackEnv.split("-")[0];
    stateAbbr === "ct" || stateAbbr === "nd" ? minHeightClass += `-${stateAbbr}` : null;
  }
  return minHeightClass;
};

// apis/auth/websiteFunctions.ts
var srp = new SRP();
var forgotPassword = async ({
  email,
  setError
}) => {
  let success = false;
  const forgotInit = await axios8({
    method: "POST",
    data: {
      email: email.toLowerCase(),
      path: window.location.origin,
      source: process.env.IS_KIOSK === "true" ? "kiosk" : "web"
    },
    url: `${process.env.AUTH_URL}/api/auth/v1/forgotPassword`,
    validateStatus: () => true
  });
  switch (forgotInit.status) {
    case 200: {
      success = true;
      break;
    }
    case 400: {
      setError({
        text: "We're sorry. We weren't able to identify you given the email provided."
      });
      DatalayerAnalytics.pushErrorEvent({
        category: "expected",
        location: "forgotPassword",
        description: `400 - ${forgotInit.data}.`
      });
      break;
    }
    case 405: {
      setError({
        text: forgotInit.data,
        link: {
          text: "Resend Verification Email",
          callback: async () => {
            return await axios8.post(
              `${process.env.AUTH_URL}/api/auth/v1/resendVerifyEmail`,
              {
                email: email.toLowerCase(),
                path: window.location.origin + window.location.pathname,
                source: process.env.IS_KIOSK === "true" ? "kiosk" : "web"
              }
            );
          }
        }
      });
      DatalayerAnalytics.pushErrorEvent({
        category: "expected",
        location: "forgotPassword",
        description: `405 - ${forgotInit.data}`
      });
      break;
    }
    case 500: {
      setError({
        text: "Failed to send reset password link."
      });
      DatalayerAnalytics.pushErrorEvent({
        category: "expected",
        location: "forgotPassword",
        description: `500 - ${forgotInit.data}`
      });
      break;
    }
  }
  return success;
};
var loginUser = async ({
  email,
  password,
  contextSetter,
  setError,
  setVisibility,
  setMode
}) => {
  let success = false;
  const login = await srp.client_login_A();
  const loginInit = await axios8.post(
    `${process.env.AUTH_URL}/api/auth/v1/login-init`,
    {
      A: login.A.toString("hex"),
      email: email.toLowerCase()
    },
    {
      validateStatus: () => true
    }
  );
  switch (loginInit.status) {
    case 401: {
      if (loginInit.data.message) {
        if (loginInit.data.message.includes("locked")) {
          setError({
            text: loginInit.data.message,
            link: {
              text: "Reset Password",
              callback: () => setMode("forgot")
            }
          });
        } else {
          setError({
            text: loginInit.data.message,
            link: {
              text: "Resend Verification Email",
              callback: async () => {
                return await axios8.post(
                  `${process.env.AUTH_URL}/api/auth/v1/resendVerifyEmail`,
                  {
                    email: email.toLowerCase(),
                    path: window.location.origin + window.location.pathname,
                    source: process.env.IS_KIOSK === "true" ? "kiosk" : "web"
                  }
                );
              }
            }
          });
        }
        DatalayerAnalytics.pushErrorEvent({
          category: "expected",
          location: "loginUser",
          description: loginInit.data.message
        });
      } else {
        DatalayerAnalytics.pushErrorEvent({
          category: "expected",
          location: "loginUser",
          description: "loginInit - 401 - No message returned"
        });
      }
      break;
    }
    case 400: {
      if (loginInit.data.message) {
        DatalayerAnalytics.pushErrorEvent({
          category: "expected",
          location: "loginUser",
          description: `loginInit - 400 - ${loginInit.data.message}`
        });
        setError({
          text: loginInit.data.message
        });
      } else {
        DatalayerAnalytics.pushErrorEvent({
          category: "expected",
          location: "loginUser",
          description: "loginInit - 400 - No message returned"
        });
      }
      break;
    }
  }
  if (loginInit.status !== 200) {
    return false;
  }
  const session = await srp.client_login_session(
    login.A.toString("hex"),
    loginInit.data.B,
    loginInit.data.s,
    email.toLowerCase(),
    password,
    login.a
  );
  const sessionInit = await axios8.post(
    `${process.env.AUTH_URL}/api/auth/v1/login`,
    {
      M1: session.M1,
      email: email.toLowerCase()
    },
    {
      withCredentials: true,
      validateStatus: () => true
    }
  );
  switch (sessionInit.status) {
    case 200: {
      if (sessionInit?.data?.message) {
        success = true;
        Cookies2.set(
          "curaleafAccountId",
          sessionInit.data.curaleafAccountId || "Successfully Authenticated!",
          process.env.COOKIE_DOMAIN ? {
            domain: process.env.COOKIE_DOMAIN
          } : void 0
        );
        const user = await axios8.get(
          `${process.env.AUTH_URL}/api/auth/v1/user`,
          {
            withCredentials: true
          }
        );
        if (user) {
          success = `User ${user.data._id} logged in.`;
          const localCart = Cookies2.get(CART_META_KEY);
          if (localCart && JSON.parse(localCart).items && user.data.cart?.items?.length && parseInt(JSON.parse(localCart).updatedAt) < parseInt(user.data.cart.updatedAt) || user.data.cart && (!localCart || !JSON.parse(localCart).items)) {
            Cookies2.set(
              CART_META_KEY,
              JSON.stringify({
                checkoutId: user.data.cart.id,
                dispensaryUniqueId: user.data.cart.dispensaryUniqueId,
                menuType: user.data.cart.pricingType,
                redirectUrl: user.data.cart.redirectUrl,
                updatedAt: user.data.cart.updatedAt
              })
            );
          }
          const optInBoolean = (optInString) => {
            if (optInString == "subscribed" || optInString == "unsubscribed") {
              return false;
            }
            return true;
          };
          contextSetter({
            _id: user.data._id,
            email: user.data.email,
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            phone: user.data.phone,
            birthday: user.data.birthday,
            state: user.data.state,
            optIn: user.data.mainSubscriptionStatus ? optInBoolean(user.data.mainSubscriptionStatus) : user.data.marketingOptIn,
            optInSms: !!user.data.smsSubscriptionStatus
          });
        }
        setVisibility(false);
        break;
      }
      break;
    }
    case 400: {
      if (sessionInit.data.message)
        setError({
          text: sessionInit.data.message
        });
      break;
    }
    case 401: {
      if (sessionInit.data.message) {
        setError({
          text: sessionInit.data.message
        });
      }
      setError({
        text: "You entered an invalid email or password."
      });
      break;
    }
  }
  return success;
};
var resetPassword = async ({
  email,
  password,
  token,
  setError
}) => {
  let success = false;
  const registration = await srp.client_register(email.toLowerCase(), password);
  const setPassReq = await axios8.post(
    `${process.env.AUTH_URL}/api/auth/v1/resetPassword`,
    {
      email: email.toLowerCase(),
      v: registration.verifier,
      s: registration.salt,
      token
    },
    {
      validateStatus: () => true
    }
  );
  switch (setPassReq.status) {
    case 200: {
      success = true;
      break;
    }
    case 500:
    /* falls through */
    case 400: {
      setError({ text: setPassReq.data });
      break;
    }
  }
  return success;
};
var registerUser = async ({
  email,
  password,
  firstName,
  lastName,
  phone,
  birthday,
  state,
  optIn,
  optInSms,
  referralToken,
  setError
}) => {
  const birthdate = new Date(birthday);
  let success = false;
  const registration = await srp.client_register(email.toLowerCase(), password);
  const registerReq = await axios8.post(
    `${process.env.AUTH_URL}/api/auth/v1/register`,
    {
      email: email.toLowerCase(),
      firstName,
      lastName,
      birthday: birthdate,
      state,
      marketingOptIn: optIn,
      smsSubscriptionStatus: optInSms,
      phone: phone.replace(/\D/g, ""),
      v: registration.verifier,
      s: registration.salt,
      source: process.env.IS_KIOSK === "true" ? "kiosk" : "web",
      path: window.location.origin + window.location.pathname,
      referralToken
    },
    {
      validateStatus: () => true
    }
  );
  switch (registerReq.status) {
    case 201: {
      success = registerReq.data;
      break;
    }
    case 400: {
      setError({ text: registerReq.data });
      break;
    }
    default: {
      setError({
        text: "Oops! Sorry about that, looks like there was an error. Please try signing up later."
      });
    }
  }
  return success;
};
var logout = async () => {
  await axios8.post(
    `${process.env.AUTH_URL}/api/auth/v1/logout`,
    {},
    {
      withCredentials: true
    }
  );
};

// apis/form/submit.ts
import moment2 from "moment";

// apis/form/springbig.ts
import axios9 from "axios";
var SPRINGBIG_API_KEY = "8nF3THTDZc2o74YkkTRFy5welQOUdmpx7D130LFL";
var SpringbigApi = class {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.springbig = axios9.create({
      baseURL: this.baseUrl,
      headers: { ["x-api-key"]: SPRINGBIG_API_KEY },
      validateStatus: () => true
    });
  }
  springbig;
  async createMember(member, authToken) {
    const { status } = await this.springbig.post(
      "/pos/v1/members",
      { member },
      { headers: { ["AUTH-TOKEN"]: authToken } }
    );
    return {
      success: status === 200,
      message: status === 200 ? "" : "Failed to create member in springbig."
    };
  }
};

// apis/form/zendesk.ts
import axios10 from "axios";
var ZendeskService = class {
  zendesk;
  constructor() {
    const base64Auth = process.env.ZENDESK_AUTHORIZATION ? Buffer.from(process.env.ZENDESK_AUTHORIZATION).toString("base64") : "";
    this.zendesk = axios10.create({
      baseURL: process.env.ZENDESK_URL,
      headers: {
        Authorization: `Basic ${base64Auth}`
      },
      validateStatus: () => true
    });
  }
  async postTicket(formData, formConfig) {
    const jsonData = {
      ticket: {
        requester: formData.email,
        subject: formConfig.contact_us.subject_line,
        description: formData.topicDescription,
        custom_fields: [
          {
            id: 12716824914715,
            value: formData.firstName
          },
          {
            id: 12716878498203,
            value: formData.lastName
          },
          {
            id: 12716885942939,
            value: formData.phone ? alphanumericSlugify(formData.phone) : ""
          },
          {
            id: 12614099396635,
            value: formData.state ? lowerScore(formData.state) : ""
          },
          {
            id: 13006294901787,
            value: formData.dispensaryPreference
          },
          {
            id: 12303428813467,
            value: formData.topic ? lowerScore(formData.topic) : ""
          },
          {
            id: 12303473665819,
            value: formData.optIn ? "on" : "off"
          }
        ]
      }
    };
    const { status } = await this.zendesk.post(
      "/api/v2/tickets.json",
      jsonData
    );
    return {
      success: status === 200 || status === 201,
      message: status === 200 || status === 201 ? "" : "Failed to submit to Zendesk!"
    };
  }
};

// apis/form/leaflogix.ts
import axios11 from "axios";
var LeaflogixApi = class {
  encodedApiKey;
  baseUrl = process.env.LEAFLOGIX_BASEURL || "https://publicapi.leaflogix.net";
  constructor(apiKey) {
    const buf = Buffer.from(apiKey);
    this.encodedApiKey = "Basic " + buf.toString("base64");
  }
  async mergeOrCreate(firstName, lastName, email, phone, city, customerType2, dob, medicalCard, zipCode) {
    const searchRes = await this.searchCustomer(email, phone);
    if (!searchRes.success) return { success: false, posCustomerId: 0 };
    const customerId = (searchRes.data.filter(
      (match) => match.matchType === "Email" || match.matchType === "Phone"
    ).find(
      (match) => searchRes.data.some(
        (otherMatch) => otherMatch.matchType !== match.matchType && otherMatch.customerId === match.customerId
      )
    ) || {}).customerId || void 0;
    const create = await this.createOrUpdateCustomer(
      email,
      firstName,
      lastName,
      city,
      customerType2,
      dob,
      medicalCard,
      customerId,
      zipCode,
      phone
    );
    return {
      success: true,
      posCustomerId: create.data.customerId
    };
  }
  async searchCustomer(email, phone) {
    const { data, status } = await axios11({
      method: "POST",
      baseURL: this.baseUrl,
      url: "customer/search",
      data: {
        emailAddress: email,
        phone
      },
      headers: {
        Authorization: this.encodedApiKey
      },
      validateStatus: () => true
    });
    return {
      success: status === 200,
      data: data ?? []
    };
  }
  async createOrUpdateCustomer(email, firstName, lastName, city, customerType2, dob, medicalCard, customerId, zipCode, phone) {
    const { data, status } = await axios11({
      method: "POST",
      baseURL: this.baseUrl,
      url: `customer/customer?bypassDeduplication=${customerId ? "true" : "false"}`,
      data: {
        customerId,
        firstName,
        lastName,
        emailAddress: email,
        status: "Active",
        city,
        customerType: customerType2,
        dateOfBirth: dob,
        mmjidNumber: medicalCard,
        postalCode: zipCode,
        phone
      },
      headers: {
        Authorization: this.encodedApiKey
      },
      validateStatus: () => true
    });
    return {
      success: status === 200,
      data
    };
  }
};

// apis/form/submit.ts
var contentstack2 = new ContentStackService();
var zendesk = new ZendeskService();
var APIResponse = (message, status) => {
  return new Response(
    JSON.stringify({
      success: !(status === 400 || status == 500),
      message
    })
  );
};
var customerType = [
  "Medical",
  "Recreational",
  "Recreational - Out of State",
  "Medical - Out of State"
];
async function POST(req) {
  const {
    submitToPos,
    submitToCDP,
    customerIsCbd,
    dispensaryUid = "",
    formData,
    formUid = "",
    brazeInstance = ""
  } = await req.json();
  const { success, data } = await contentstack2.getSubmitFormData(
    brazeInstance,
    dispensaryUid,
    formUid,
    formData.state ? capitalize(formData.state) : ""
  );
  if (!success) {
    return APIResponse(
      "Could not retrieve additional data needed to submit",
      400
    );
  }
  const { braze, dispensary, formConfig, springbigToken } = data;
  let eventName = formUid === "prerelease" ? "DispensaryComingSoon" : formUid === "opt-in-banner" ? "mailingOptIn" : "";
  let formBrazeInstance = brazeInstance && braze && Object.keys(braze).length > 0 ? braze : void 0;
  if (!formConfig && formUid !== "prerelease" && formUid !== "opt-in-banner") {
    return APIResponse("Could not retrieve form configuration.", 400);
  } else if (formConfig) {
    eventName = formConfig.braze_custom_event_name ?? "";
    if (formConfig.type === "Contact Us" && formConfig.contact_us?.submit_to_zendesk) {
      try {
        const zenmission = await zendesk.postTicket(formData, formConfig);
        if (!zenmission.success) console.error(zenmission.message);
      } catch (err) {
      }
    }
  }
  if (submitToPos && formData.customerType && !customerType.includes(formData.customerType)) {
    return APIResponse(
      'Invalid customerType. Acceptable values are "Medical" or "Recreational"',
      400
    );
  } else if (submitToPos) {
    if (!dispensaryUid) {
      return APIResponse(
        "dispensaryUid is required when attempting to submit to the POS",
        400
      );
    }
    if (!dispensary) {
      return APIResponse("dispensaryUid not valid", 400);
    }
    const leaflogix = new LeaflogixApi(dispensary.ll_api_key || "");
    await leaflogix.mergeOrCreate(
      formData.firstName ?? "",
      formData.lastName ?? "",
      formData.email,
      formData.phone,
      dispensary.city ?? "unknown",
      formData.customerType,
      formData.dob ?? "",
      formData.medicalCard,
      formData.zipCode
    );
  }
  if (submitToCDP) {
    if (formConfig?.target_crm_cdpConnection?.edges && !formBrazeInstance) {
      formBrazeInstance = formConfig.target_crm_cdpConnection?.edges[0]?.node?.braze ?? void 0;
    }
    if (!formBrazeInstance?.instance_url || !formBrazeInstance?.api_key || !formBrazeInstance?.app_key) {
      return APIResponse("CDP Config Missing", 500);
    }
    const braze2 = new BrazeApi(
      formBrazeInstance.instance_url,
      formBrazeInstance.api_key,
      formBrazeInstance.app_key
    );
    if (formData.optIn) {
      await braze2.submitIntakeForm(formData, customerIsCbd, eventName);
      if (formData.phone && formData.preferredMethodOfCommunication && formData.preferredMethodOfCommunication.phone) {
        if (!process.env.SPRINGBIG_CBD_AUTHTOKEN || !process.env.SPRINGBIG_DEFAULT_AUTHTOKEN || !process.env.SPRINGBIG_BASE_URL) {
          return APIResponse("CDP SMS key not configured", 500);
        }
        const brazecustomer = await braze2.lookupCustomer(formData.email);
        const authToken = customerIsCbd ? process.env.SPRINGBIG_CBD_AUTHTOKEN : springbigToken || process.env.SPRINGBIG_DEFAULT_AUTHTOKEN;
        const springbig = new SpringbigApi(process.env.SPRINGBIG_BASE_URL);
        await springbig.createMember(
          {
            pos_user: brazecustomer?.userData ? brazecustomer.userData.braze_id : "",
            pos_type: "Braze",
            phone_number: formData.phone,
            first_name: formData.firstName || "",
            last_name: formData.lastName || "",
            email: formData.email,
            birthday: formData.dob ? moment2(formData.dob).format("YYYY-MM-DD") : void 0,
            zip: formData.zipCode,
            purpose: formData.customerType ? formData.customerType === "Medical" || formData.customerType === "Medical - Out of State" ? "medical" : "recreational" : void 0,
            interest_list: [],
            location_list: []
          },
          authToken
        );
      }
    }
  }
  return APIResponse("", 200);
}

// apis/dutchiePlus/dutchiePlus.service.ts
import axios12 from "axios";
import axiosRetry2 from "axios-retry";
import request from "graphql-request";

// queries/dutchiePlus.fragments.ts
var productFields = `
brand {
  id
  name
  description
  imageUrl
}
cannabinoids {
  cannabinoidId
  unit
  value
  cannabinoid {
      description
      id
      name
  }
}
category
description
descriptionHtml
effects
id
image
images {
  id
  url
  label
  description
}
menuTypes
name
posId
potencyCbd {
  formatted
  range
  unit
}
potencyThc {
  formatted
  range
  unit
}
posMetaData {
  batchName
}
productBatchId
staffPick
strainType
subcategory
tags
terpenes {
  id
  terpene {
      aliasList
      aromas
      description
      effects
      id
      name
      potentialHealthBenefits
      unitSymbol
  }
  name
  terpeneId
  unit
  unitSymbol
  value
}
variants {
  id
  option
  priceMed
  priceRec
  specialConditionIds
  specialPriceMed
  specialPriceRec
  specialRewardIds
  quantity
  flowerEquivalent {
    unit
    value
  }
}`;
var productFragment = `
    fragment productFragment on Product {
      ${productFields}
    }`;
var checkoutFragment = `
    address {
        street1
        street2
        city
        state
        zip
        formatted
        geometry {
            coordinates
            type
        }
        deliverable
        valid
    }
    id
    orderType
    pricingType
    items {
        id
        option
        isDiscounted
        basePrice
        taxes {
            total
            cannabis
            sales
        }
        discounts {
          total  
        }
        productId
        product {
          brand {
            id
            name
          }
          cannabinoids {
            cannabinoidId
            unit
            value
            cannabinoid {
              description
              id
              name
            }
          }
          category
          description
          descriptionHtml
          effects
          id
          image
          images {
            url
          }
          name
          posId
          potencyCbd {
            formatted
            range
            unit
          }
          potencyThc {
            formatted
            range
            unit
          }
          staffPick
          strainType
          subcategory
          terpenes {
            id
            name
            terpene {
              aliasList
              aromas
              description
              effects
              id
              name
              potentialHealthBenefits
              unitSymbol
            }
            terpeneId
            unit
            unitSymbol
            value
          }
          variants {
            flowerEquivalent {
              unit
              value
            }
            id
            option
            priceMed
            priceRec
            quantity
            specialPriceMed
            specialPriceRec
          }
        }
        quantity
        valid
    }
    priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
    }
    redirectUrl
    updatedAt
  `;
var orderFragment = `
delivery
customer {
  email
}
createdAt
discounts {
  total
}
fees {
  total
}
dispensaryName
foreignId
id
items {
  product {
    ${productFields}
  }
  option
  price
  productId
  quantity
  subtotal
}
medical
orderNumber
pickup
recreational
reservationDate {
  endTime
  startTime
}
status
subtotal
taxes {
  total
}
total
`;
var SPECIALS_QUERY = `
    query SpecialsQuery ($retailerId: ID!) {
      specials (retailerId: $retailerId) {
        id
        name
        type
        redemptionLimit
        menuType
        emailConfiguration {
          description
          descriptionHtml
          subject
          heading
          enabled
        }
        scheduleConfiguration {
          startStamp
          endStamp
          days
          startTime
          endTime
          setEndDate
          endDate
        }
        menuDisplayConfiguration {
          name
          description
          image
        }
      }
    }
`;
var SPECIALS_PRODUCTS_QUERY = (specialId) => `
  query SpecialProducts(
    $retailerId: ID!
    $menuType: MenuType
  ) {
    menu(
      retailerId: $retailerId,
      menuType: $menuType,
      pagination: { offset: 0, limit: 2000 },
      filter: { menuSection: { type: SPECIALS, specialId: ["${specialId}"] }},
      sort: { direction: DESC, key: POPULAR }
    ){
      products {
        id
      }
    }
  }
`;

// models/dutchiePlus/generated.ts
var Category = {
  Accessories: "ACCESSORIES",
  Apparel: "APPAREL",
  Cbd: "CBD",
  Clones: "CLONES",
  Concentrates: "CONCENTRATES",
  Edibles: "EDIBLES",
  Flower: "FLOWER",
  NotApplicable: "NOT_APPLICABLE",
  Orals: "ORALS",
  PreRolls: "PRE_ROLLS",
  Seeds: "SEEDS",
  Tinctures: "TINCTURES",
  Topicals: "TOPICALS",
  Vaporizers: "VAPORIZERS"
};

// queries/graph.ts
var CustomQuery = () => `
  ${productFragment}
  query fetchCustomProducts (
      $retailerId: ID!
      $menuType: MenuType!
      $category: MenuSectionFilterType!
      $listName: String
    ) {
        menu(
          retailerId: $retailerId,
          menuType: $menuType,
          pagination: { offset: 0, limit: 50},
          filter: { menuSection: { type: $category, name: $listName }},
          sort: { direction: DESC, key: POPULAR }
          ) {
        products {
          ...productFragment
        }
      }
    }
`;
var TagQuery = () => `
  ${productFragment}
  query fetchTagProducts (
      $retailerId: ID!
      $menuType: MenuType!
      $category: String
    ) {
        menu(
          retailerId: $retailerId,
          menuType: $menuType,
          pagination: { offset: 0, limit: 50},
          filter: {  tags: [$category] },
          sort: { direction: DESC, key: POPULAR }
          ) {
        products {
          ...productFragment
        }
      }
    }
`;

// apis/dutchiePlus/dutchiePlus.service.ts
var dutchie = axios12.create({
  baseURL: process.env.DUTCHIE_PLUS_URL,
  timeout: 12e3,
  headers: {
    Authorization: `Bearer public-${process.env.DUTCHIE_PLUS_PUBLIC_TOKEN}`
  }
});
var shouldRetry = (error) => {
  return axiosRetry2.isNetworkError(error) || // Retry on network error
  axiosRetry2.isRetryableError(error) || // Retry on general retryable errors
  error.code === "ECONNABORTED" || // Retry on connection timeouts
  error.response && error.response.status === 429 || // Retry on HTTP status code 429
  false;
};
var dedupeProducts = (productsArr) => {
  const productIdSet = /* @__PURE__ */ new Set();
  const productsArrCopy = productsArr.slice();
  productsArr.forEach((product, i) => {
    if (!productIdSet.has(product.id)) {
      productIdSet.add(product.id);
    } else {
      productsArrCopy.splice(i, 1);
    }
  });
  return productsArrCopy;
};
var isNotPaginated = process.env.NEXT_PUBLIC_DISABLE_DUTCHIE_PAGINATION === "true";
axiosRetry2(dutchie, {
  retries: 2,
  retryDelay: (retryCount) => {
    return retryCount * 3e3;
  },
  retryCondition: shouldRetry
});
var DutchiePlusService = class {
  baseUrl = process.env.DUTCHIE_PLUS_URL;
  requestHeaders = {
    authorization: `Bearer public-${process.env.DUTCHIE_PLUS_PUBLIC_TOKEN}`
  };
  getRetailer = (retailerId) => {
    const params = {
      retailerId
    };
    const query = `
      fragment hoursDayFragment on HoursDay {
        active
        start
        end
      }
      
      fragment hoursFragment on Hours {
        Sunday {
          ...hoursDayFragment
        }
        Monday {
          ...hoursDayFragment
        }
        Tuesday {
          ...hoursDayFragment
        }
        Wednesday {
          ...hoursDayFragment
        }
        Thursday {
          ...hoursDayFragment
        }
        Friday {
          ...hoursDayFragment
        }
        Saturday {
          ...hoursDayFragment
        }
      }
      
      fragment retailerFragment on Retailer {
        address
        banner {
          html
        }
      #   categoryLimits {
      #       name
      #       value
      #   }
        coordinates {
            latitude
            longitude
        }
        deliverySettings {
          afterHoursOrderingForDelivery
          afterHoursOrderingForPickup
          deliveryArea
          deliveryFee
          deliveryMinimum
          disablePurchaseLimits
          limitPerCustomer
          pickupMinimum
          scheduledOrderingForDelivery
          scheduledOrderingForPickup
        }
      #   description
        fulfillmentOptions {
        curbsidePickup
          delivery
          driveThruPickup
          pickup
        }
        hours {
          delivery {
            ...hoursFragment
          }
          pickup {
            ...hoursFragment
          }
          regular {
              ...hoursFragment
          }
          special {
            startDate
            endDate
            hoursPerDay {
              date
              deliveryHours {
                ...hoursDayFragment
              }
              pickupHours {
                ...hoursDayFragment
              }
            }
            name
          }
        }
        id
        menuTypes
        name
        paymentOptions {
          aeropay
          alt36
          canPay
          cashless
          cashOnly
          check
          creditCard
          creditCardAtDoor
          creditCardByPhone
          debitOnly
          hypur
          linx
          merrco
          payInStore
          paytender
        }
        settings {
            menuWeights
        }
      }
      
      query Retailer ($retailerId: ID!) {
        retailer(id: $retailerId) {
          ...retailerFragment
        }
      }`;
    return request(
      this.baseUrl,
      query,
      params,
      this.requestHeaders
    ).then(
      ({ retailer }) => {
        return {
          success: true,
          data: retailer
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  };
  getRetailers = () => {
    const query = `
      fragment hoursDayFragment on HoursDay {
        active
        start
        end
      }
      
      fragment hoursFragment on Hours {
        Sunday {
          ...hoursDayFragment
        }
        Monday {
          ...hoursDayFragment
        }
        Tuesday {
          ...hoursDayFragment
        }
        Wednesday {
          ...hoursDayFragment
        }
        Thursday {
          ...hoursDayFragment
        }
        Friday {
          ...hoursDayFragment
        }
        Saturday {
          ...hoursDayFragment
        }
      }
      
      fragment retailerFragment on Retailer {
        address
        banner {
          html
        }
        coordinates {
            latitude
            longitude
        }
        deliverySettings {
          afterHoursOrderingForDelivery
          afterHoursOrderingForPickup
          deliveryArea
          deliveryFee
          deliveryMinimum
          disablePurchaseLimits
          limitPerCustomer
          pickupMinimum
          scheduledOrderingForDelivery
          scheduledOrderingForPickup
        }
        fulfillmentOptions {
        curbsidePickup
          delivery
          driveThruPickup
          pickup
        }
        hours {
          delivery {
            ...hoursFragment
          }
          pickup {
            ...hoursFragment
          }
          regular {
              ...hoursFragment
          }
          special {
            startDate
            endDate
            hoursPerDay {
              date
              deliveryHours {
                ...hoursDayFragment
              }
              pickupHours {
                ...hoursDayFragment
              }
            }
            name
          }
        }
        id
        menuTypes
        name
        paymentOptions {
          aeropay
          alt36
          canPay
          cashless
          cashOnly
          check
          creditCard
          creditCardAtDoor
          creditCardByPhone
          debitOnly
          hypur
          linx
          merrco
          payInStore
          paytender
        }
        settings {
            menuWeights
        }
      }
      
      query Retailer {
        retailers {
          ...retailerFragment
        }
      }`;
    return request(this.baseUrl, query, {}, this.requestHeaders).then(
      ({
        retailers
      }) => {
        return {
          success: true,
          data: retailers
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  };
  async getProductsForRetailerMenu(retailerId, menuType, options = {}) {
    const { excludeCategory } = options;
    const defaultFilters = [
      Category.Accessories,
      Category.Apparel,
      Category.Cbd,
      Category.Clones,
      Category.Concentrates,
      Category.Edibles,
      Category.Flower,
      Category.NotApplicable,
      Category.Orals,
      Category.PreRolls,
      Category.Seeds,
      Category.Tinctures,
      Category.Topicals,
      Category.Vaporizers
    ];
    const isExclusionQuery = (excludeCategory || []).length > 0;
    const generateMenuQuery = (categories = null) => {
      if (isExclusionQuery && categories) {
        return categories.map((category) => {
          const filter = `filter: { category: ${category} }`;
          return `
            ${category}: menu(
              retailerId: $retailerId,
              menuType: $menuType,
              pagination: { offset: $offset, limit: ${isNotPaginated ? 2e3 : 100} },
              sort: { direction: DESC, key: POPULAR },
              ${filter}
            ){
              productsCount
              products {
                ...productFragment
              }
            }
          `;
        }).join("\n");
      } else {
        return `
          menu(
            retailerId: $retailerId,
            menuType: $menuType,
            pagination: { offset: $offset, limit: ${isNotPaginated ? 2e3 : 100} },
            sort: { direction: DESC, key: POPULAR },
          ){
            productsCount
            products {
              ...productFragment
            }
          }
        `;
      }
    };
    const generateQuery = (menuQuery) => `
    ${productFragment}

    query fetchProductsForRetailer (
        $retailerId: ID!
        $menuType: MenuType!
        $offset: Int!
    ) {
        ${menuQuery}
    }`;
    let query = "";
    let categoryFilter = [...defaultFilters];
    if (isExclusionQuery) {
      categoryFilter = defaultFilters.filter(
        (category) => !excludeCategory?.includes(category)
      );
      query = generateQuery(generateMenuQuery(categoryFilter));
    } else {
      query = generateQuery(generateMenuQuery());
    }
    const productsArr = [];
    let offset = 0;
    const params = {
      retailerId,
      menuType,
      offset
    };
    try {
      const response = await request(this.baseUrl, query, params, this.requestHeaders);
      const products = Object.keys(response).reduce(
        (acc, key) => [...acc, ...response[key].products],
        []
      );
      if (!isNotPaginated) {
        const dedupedProducts = dedupeProducts(
          products
        );
        productsArr.push(...dedupedProducts);
        const productsCount = response.menu.productsCount;
        const callCount = Math.ceil(productsCount / 100);
        offset = 100;
        const pagedParams = {
          retailerId,
          menuType,
          offset
        };
        for (let i = 1; i < callCount; i++) {
          const pagedRequest = await request(this.baseUrl, query, pagedParams, this.requestHeaders);
          const pagedProducts = Object.keys(pagedRequest).reduce(
            (acc, key) => [...acc, ...pagedRequest[key].products],
            []
          );
          const dedupedProducts2 = dedupeProducts(
            pagedProducts
          );
          productsArr.push(...dedupedProducts2);
          if (typeof pagedParams.offset == "number") {
            pagedParams.offset += 100;
          }
        }
      }
      const mappedArray = (isNotPaginated ? products : productsArr).map(
        (product) => {
          if (product.category === Category.Apparel) {
            product.variants = product.variants.sort((a, b) => {
              const sizes = ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"];
              if (sizes.includes(a.option.toUpperCase()) && sizes.includes(b.option.toUpperCase())) {
                return sizes.indexOf(a.option.toUpperCase()) - sizes.indexOf(b.option.toUpperCase());
              }
              return 0;
            });
          }
          return product;
        }
      );
      return {
        success: true,
        data: mappedArray
      };
    } catch (err) {
      if (options.logger) {
        options.logger.error(err);
      }
      if (err.response?.data?.menu) {
        const response = err.response?.data;
        const products = Object.keys(response).reduce(
          (acc, key) => [...acc, ...response[key].products],
          []
        );
        return {
          success: true,
          data: products
        };
      }
      return {
        success: false,
        errorMessage: err
      };
    }
  }
  async getProduct(productId, retailerId) {
    const query = `
    ${productFragment}

    query Product (
      $productId: ID!
      $retailerId: ID!
    ) {
      product(
        id: $productId,
        retailerId: $retailerId
      ){
        ...productFragment
      }
    }`;
    const params = {
      productId,
      retailerId
    };
    try {
      const response = await request(this.baseUrl, query, params, this.requestHeaders);
      return {
        success: true,
        data: response.product
      };
    } catch (err) {
      if (err.response?.data?.menu) {
        const response = err.response?.data;
        return {
          success: true,
          data: response.product
        };
      }
      return {
        success: false,
        errorMessage: err
      };
    }
  }
  async getAllProductsForRetailerMenu(retailerId) {
    const query = `
    ${productFragment}

    query fetchProductsForRetailer (
        $retailerId: ID!
        $offset: Int!
    ) {
      menu(
        retailerId: $retailerId,
        pagination: { offset: $offset, limit: ${isNotPaginated ? 2e3 : 100} },
        sort: { direction: DESC, key: POPULAR }
      ){
        productsCount
        products {
          ...productFragment
        }
      }
    }`;
    const productsArr = [];
    let offset = 0;
    const params = {
      retailerId,
      offset
    };
    try {
      const response = await request(this.baseUrl, query, params, this.requestHeaders);
      const products = Object.keys(response).reduce(
        (acc, key) => [...acc, ...response[key].products],
        []
      );
      if (!isNotPaginated) {
        const dedupedProducts = dedupeProducts(
          products
        );
        productsArr.push(...dedupedProducts);
        const productsCount = response.menu.productsCount;
        const callCount = Math.ceil(productsCount / 100);
        offset = 100;
        const pagedParams = {
          retailerId,
          offset
        };
        for (let i = 1; i < callCount; i++) {
          const pagedRequest = await request(this.baseUrl, query, pagedParams, this.requestHeaders);
          const pagedProducts = Object.keys(pagedRequest).reduce(
            (acc, key) => [...acc, ...pagedRequest[key].products],
            []
          );
          const dedupedProducts2 = dedupeProducts(
            pagedProducts
          );
          productsArr.push(...dedupedProducts2);
          if (typeof pagedParams.offset == "number") {
            pagedParams.offset += 100;
          }
        }
      }
      const mappedArray = (isNotPaginated ? products : productsArr).map(
        (product) => {
          if (product.category === Category.Apparel) {
            product.variants = product.variants.sort((a, b) => {
              const sizes = ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"];
              if (sizes.includes(a.option.toUpperCase()) && sizes.includes(b.option.toUpperCase())) {
                return sizes.indexOf(a.option.toUpperCase()) - sizes.indexOf(b.option.toUpperCase());
              }
              return 0;
            });
          }
          return product;
        }
      );
      return {
        success: true,
        data: mappedArray
      };
    } catch (err) {
      if (err.response?.data?.menu) {
        const response = err.response?.data;
        const products = Object.keys(response).reduce(
          (acc, key) => [...acc, ...response[key].products],
          []
        );
        return {
          success: true,
          data: products
        };
      }
      return {
        success: false,
        errorMessage: err
      };
    }
  }
  async getProductsForSpecial(retailerId, specialId, menuType) {
    let offset = 0;
    const params = {
      retailerId,
      specialId,
      offset
    };
    if (menuType) {
      params.menuType = menuType;
    }
    const query = `
    ${productFragment}
    
    query SpecialProducts(
        $retailerId: ID!
        $specialId: [String]!
        $offset: Int!,
        ${params.menuType ? "$menuType: MenuType!" : ""}
    ) {
      menu(
          retailerId: $retailerId,
          pagination: { offset: $offset, limit: ${isNotPaginated ? 2e3 : 100} },
          filter: { menuSection: { type: SPECIALS, specialId: $specialId }},
          ${params.menuType ? "menuType: $menuType" : ""}
      ){
        productsCount
        products {
          ...productFragment
        }
      }
    }
    `;
    try {
      const productsArr = [];
      const response = await request(
        this.baseUrl,
        query,
        params,
        this.requestHeaders
      );
      if (!isNotPaginated) {
        const dedupedProducts = dedupeProducts(
          response.menu.products
        );
        productsArr.push(...dedupedProducts);
        const productsCount = response.menu.productsCount;
        const callCount = Math.ceil(productsCount / 100);
        offset = 100;
        const pagedParams = {
          retailerId,
          specialId,
          offset
        };
        for (let i = 1; i < callCount; i++) {
          const pagedRequest = await request(
            this.baseUrl,
            query,
            pagedParams,
            this.requestHeaders
          );
          const dedupedProducts2 = dedupeProducts(
            pagedRequest.menu.products
          );
          productsArr.push(...dedupedProducts2);
          productsArr.push(...pagedRequest.menu.products);
          if (typeof pagedParams.offset == "number") {
            pagedParams.offset += 100;
          }
        }
      }
      return {
        success: true,
        data: isNotPaginated ? response.menu.products : productsArr
      };
    } catch (err) {
      return {
        success: false,
        errorMessage: err
      };
    }
  }
  getSpecialsForRetailer(retailerId) {
    const params = {
      retailerId
    };
    const query = `
    query SpecialsQuery ($retailerId: ID!) {
      specials (retailerId: $retailerId) {
        id
        name
        type
        redemptionLimit
        menuType
        emailConfiguration {
          description
          descriptionHtml
          subject
          heading
          enabled
        }
        scheduleConfiguration {
          startStamp
          endStamp
          days
          startTime
          endTime
          setEndDate
          endDate
        }
        menuDisplayConfiguration {
          name
          description
          image
        }
      }
    }`;
    return request(
      this.baseUrl,
      query,
      params,
      this.requestHeaders
    ).then(
      ({ specials }) => {
        return {
          success: true,
          data: specials
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getSpecialsForRetailerStockChecked(retailerId) {
    const params = {
      retailerId
    };
    const query = `
    query SpecialsQuery ($retailerId: ID!) {
      specials (retailerId: $retailerId) {
        id
        name
        type
        redemptionLimit
        menuType
        emailConfiguration {
          description
          descriptionHtml
          subject
          heading
          enabled
        }
        scheduleConfiguration {
          startStamp
          endStamp
          days
          startTime
          endTime
          setEndDate
          endDate
        }
        menuDisplayConfiguration {
          name
          description
          image
        }
      }
    }`;
    return request(
      this.baseUrl,
      query,
      params,
      this.requestHeaders
    ).then(
      async ({
        specials
      }) => {
        for (let i = specials.length - 1; i >= 0; i--) {
          const special = specials[i];
          const count = await this.getProductsForSpecialMinimal(
            retailerId,
            special.id
          );
          if (!count.success || count.data === 0) {
            specials.splice(i, 1);
          }
        }
        return {
          success: true,
          data: specials
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async getProductsForSpecialMinimal(retailerId, specialId) {
    const params = {
      retailerId,
      specialId
    };
    const query = `
    query SpecialProducts(
        $retailerId: ID!
        $specialId: [String]!
    ) {
      menu(
          retailerId: $retailerId,
          filter: { menuSection: { type: SPECIALS, specialId: $specialId }},
          ${params.menuType ? "menuType: $menuType" : ""}
      ){
        productsCount
      }
    }
    `;
    const response = await request(
      this.baseUrl,
      query,
      params,
      this.requestHeaders
    );
    return {
      success: true,
      data: response.menu.productsCount
    };
  }
  createCheckout(retailerId, orderType, pricingType, metadata) {
    const params = {
      retailerId,
      orderType,
      pricingType,
      metadata
    };
    const query = `
      mutation CreateCheckout(
        $retailerId: ID!
        $address: CheckoutAddressInput
        $orderType: OrderType!
        $pricingType: PricingType!
        $metadata: JSON
      ) {
        createCheckout(
          retailerId: $retailerId
          address: $address
          orderType: $orderType
          pricingType: $pricingType
          metadata: $metadata
        ) {
          ${checkoutFragment}
        }
      }`;
    return request(
      this.baseUrl,
      query,
      params,
      this.requestHeaders
    ).then(
      ({
        createCheckout
      }) => {
        return {
          success: true,
          data: createCheckout
        };
      }
    ).catch(
      (err) => {
        return {
          success: false,
          errorMessage: err
        };
      }
    );
  }
  getCheckout(retailerId, checkoutId) {
    const params = {
      retailerId,
      checkoutId
    };
    const query = `
      query Checkout(
        $retailerId: ID!
        $checkoutId: ID!
      ) {
        checkout(
            retailerId: $retailerId
            id: $checkoutId
          ) {
          ${checkoutFragment}
        }
      }`;
    return request(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        checkout
      }) => {
        return {
          success: true,
          data: checkout
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err.response?.errors?.length ? err.response.errors[0].message : err,
        status: err.response?.status
      };
    });
  }
  addItemToCheckout(retailerId, checkoutId, productId, quantity, option) {
    const params = {
      retailerId,
      checkoutId,
      productId,
      quantity,
      option
    };
    const query = `
      mutation AddItemToCheckout(
        $retailerId: ID!
        $checkoutId: ID!
        $productId: ID!
        $quantity: Int!
        $option: String!
      ) {
        addItem(
          retailerId: $retailerId
          checkoutId: $checkoutId
          productId: $productId
          quantity: $quantity
          option: $option
        ) {
          ${checkoutFragment}
        }
      }`;
    return request(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        addItem
      }) => {
        return {
          success: true,
          data: addItem
        };
      }
    ).catch(
      (err) => {
        return {
          success: false,
          errorMessage: err
        };
      }
    );
  }
  removeItemFromCheckout(retailerId, checkoutId, itemId) {
    const params = {
      retailerId,
      checkoutId,
      itemId
    };
    const query = `
      mutation RemoveItemFromCheckout( 
        $retailerId: ID!
        $checkoutId: ID!
        $itemId: ID!
      ) {
        removeItem(
          retailerId: $retailerId
          checkoutId: $checkoutId
          itemId: $itemId
        ) {
          ${checkoutFragment}
        }
      }`;
    return request(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        removeItem
      }) => {
        return {
          success: true,
          data: removeItem
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async addToCartGraph(retailerId, orderType, pricingType, productId, quantity, option, checkoutId, metadata) {
    if (checkoutId) {
      return this.addItemToCheckout(
        retailerId,
        checkoutId,
        productId,
        quantity,
        option
      );
    } else {
      const checkout = await this.createCheckout(
        retailerId,
        orderType,
        pricingType,
        metadata
      );
      if (checkout.success) {
        return this.addItemToCheckout(
          retailerId,
          checkout.data.id,
          productId,
          quantity,
          option
        );
      } else {
        return checkout;
      }
    }
  }
  updateCheckout(retailerId, checkoutId, orderType, pricingType, metadata) {
    const params = {
      retailerId,
      checkoutId,
      orderType,
      pricingType,
      metadata
    };
    const query = `
    mutation UpdateCheckout(
      $retailerId: ID!
      $checkoutId: ID!
      $orderType: OrderType!
      $pricingType: PricingType!
      $metadata: JSON
    ) {
      updateCheckout(checkoutId: $checkoutId, retailerId: $retailerId, orderType: $orderType, pricingType: $pricingType, metadata: $metadata) {
        ${checkoutFragment}
      }
    }`;
    return request(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        updateCheckout
      }) => {
        return {
          success: true,
          data: updateCheckout
        };
      }
    ).catch(
      (err) => {
        return {
          success: false,
          errorMessage: err
        };
      }
    );
  }
  async getOrderByOrderNumber(retailerId, orderNumber) {
    const params = {
      retailerId,
      orderNumber
    };
    const query = `
      query Orders(
        $retailerId: ID!
        $orderNumber: String!
      ) {
        orders(
            retailerId: $retailerId
            filter: { orderNumber: $orderNumber }
          ) {
          ${orderFragment}
        }
      }`;
    try {
      const response = await request(this.baseUrl, query, params, {
        ...this.requestHeaders,
        authorization: `Bearer ${process.env.DUTCHIE_PLUS_PRIVATE_TOKEN}`
      });
      return {
        success: true,
        data: response.orders[0]
      };
    } catch (err) {
      return {
        success: false,
        errorMessage: err
      };
    }
  }
  getCustomListProducts(retailerId, menuType, category, type, listName = "") {
    const params = {
      retailerId,
      menuType,
      category,
      listName
    };
    let query = CustomQuery();
    if (type === "tag") query = TagQuery();
    return request(
      this.baseUrl,
      query,
      params,
      this.requestHeaders
    ).then(
      ({
        menu
      }) => {
        return {
          success: true,
          data: menu.products
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getSpecialsWithProducts(retailerId, menuType) {
    try {
      const { data: allSpecials } = await dutchie.post("", {
        query: SPECIALS_QUERY,
        variables: {
          retailerId
        }
      });
      const validSpecials = [];
      for (const special of allSpecials.data.specials) {
        const { data: specialProducts } = await dutchie.post("", {
          query: SPECIALS_PRODUCTS_QUERY(special.id),
          variables: {
            retailerId,
            menuType
          }
        });
        if (specialProducts?.data?.menu?.products?.length) {
          validSpecials.push({
            ...special,
            products: specialProducts.data.menu.products.map(
              (p) => p.id
            )
          });
        }
      }
      return {
        success: true,
        data: validSpecials.filter((special) => special.products?.length)
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: error instanceof Error ? error.message : "An error occurred."
      };
    }
  }
  getRetailersByProximity = (coordinates) => {
    const params = {
      ...coordinates
    };
    const query = `
      query RetailersNearLocation($latitude: Float!, $longitude: Float!) {
        retailersNearLocation(
          location: {
            coordinates: { latitude: $latitude, longitude: $longitude }
            maxDistance: 50
          }
        ) {
          retailer {
            fulfillmentOptions {
              delivery
              pickup
            }
            id
          }
          locationDetail {
            distance
            unit
            validForDelivery
          }
        }
      }
    `;
    return request(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        retailersNearLocation
      }) => {
        return {
          success: true,
          data: retailersNearLocation
        };
      }
    ).catch(
      (err) => {
        return {
          success: false,
          errorMessage: err
        };
      }
    );
  };
  async getAllBrands(retailerId) {
    const params = {
      retailerId
    };
    const query = `
    query Brands(
        $retailerId: ID!
    ) {
      menu(
          retailerId: $retailerId,
          pagination: { offset: 0, limit: 2000 },
      ){
        brands {
          id
          name
          description
          imageUrl
        }
      }
    }
    `;
    try {
      const response = await request(this.baseUrl, query, params, this.requestHeaders);
      return {
        success: true,
        data: response.menu.brands
      };
    } catch (err) {
      return {
        success: false,
        errorMessage: err
      };
    }
  }
};

// apis/strapi/b2c-strapi.service.ts
import axios13 from "axios";
import qs from "qs";
var B2cStrapiService = class {
  strapi = axios13.create({
    baseURL: process.env.B2C_STRAPI_URL,
    headers: {
      Authorization: process.env.B2C_STRAPI_AUTHORIZATION
    }
  });
  filteredData(slug, state) {
    return qs.stringify(
      {
        populate: {
          Dispensaries: {
            filters: {
              Slug: {
                $eq: slug
              }
            }
          },
          States: {
            filters: {
              Title: {
                $eq: state
              }
            }
          },
          Banners: {
            populate: "*"
          }
        },
        filters: {
          $or: [
            {
              Dispensaries: {
                Slug: {
                  $eq: slug
                }
              }
            },
            {
              States: {
                Title: {
                  $eq: state
                }
              }
            }
          ]
        }
      },
      {
        encodeValuesOnly: true
      }
    );
  }
  getAlerts(slug, state) {
    return this.strapi.get(`/api/alerts?${this.filteredData(slug, state)}`).then(
      ({
        data
      }) => ({
        success: true,
        data
      })
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getStorefrontBanners(slug, state) {
    return this.strapi.get(`/api/storefront-banners?${this.filteredData(slug, state)}`).then(
      ({
        data
      }) => {
        const banners = [];
        for (const banner of data.data) {
          banners.push(...banner.attributes.Banners);
        }
        return {
          success: true,
          data: banners
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
};

// models/ui/consolidate.ts
var DAYS_OF_WEEK = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
];

// queries/loyaltyReferral.ts
var SUBMIT_REFERRAL = `
  mutation Mutation($emailToRefer: String!, $path: String!) {
    loyaltyReferral(emailToRefer: $emailToRefer, path: $path) {
      message
      success
    }
  }
`;
var ACTIVE_REFERRALS = `
  query ActiveLoyaltyReferrals {
    activeLoyaltyReferrals {
      email
      created_at
      status
    }
  }
`;
var REFERRAL_URL = `
  query REFERRAL_URL {
    loyaltyReferralUrl {
      token
    }
  }
`;
export {
  ACTIVE_REFERRALS,
  AzureGeoApi,
  B2cStrapiService,
  Braze,
  BrazeApi,
  ContentStackService,
  curaql_exports as Curaql,
  CustomerIntegration,
  DAYS_OF_WEEK,
  DatalayerAnalytics,
  DutchiePlusService,
  GetCOA,
  GoogleGeoIp,
  REFERRAL_URL,
  SRP,
  SUBMIT_REFERRAL,
  SpringbigApi,
  alphanumericSlugify,
  calculateDecisionTree,
  capitalize,
  deslugify,
  dutchieFriendlyName,
  forgotPassword,
  formStatesNDispensariesMap,
  getMinHeightClass,
  getSlug,
  isFakeNumber,
  loginUser,
  logout,
  registerUser,
  resetPassword,
  slugify,
  snakeCase,
  POST as submitForm
};
