import { useEffect, useState } from 'react';

import { DatalayerAnalytics } from 'services';
import { AccountBanner } from '../components/Account/Banner';
import { PageState } from '../components/PageState';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';
import { useSiteWideContext } from '../hooks/siteWideContext';
import { StorefrontPageProps } from './storefrontPage';
import { StorefrontCarousel } from '../components/StorefrontCarousel';
import { StorefrontBanners } from '../components/StorefrontBanners';
import { WideBanner } from '../components/WideBanner';
import { productsAnalyticsMap } from '../utils/storefrontCarouselsMap';
import { shopHomeJsonLD } from '../utils/jsonLD';
import { moodiDayScript } from '../utils/moodiDayScript';
import { OverviewCard } from '../components/DispensaryOverview/OverviewCard';
import { PackageComponentWrapper } from '../components/PackageComponentWrapper';

type HomePageProps = Omit<StorefrontPageProps, 'formConfiguration'>;

export const DutchiePlusHomePage = ({
  favoredBrands,
  productLists,
  storefrontBanners
}: HomePageProps) => {
  const { currentPathDispensary, defaultMenuType } = useDispensaryPathContext();
  const { addItemToCart, categories, isMobile, width } = useSiteWideContext();
  const [showWideBanner, setShowWideBanner] = useState(true);

  useEffect(() => {
    if (currentPathDispensary?.location?.state) {
      DatalayerAnalytics.pushPageView({
        page_type: 'shop/home',
        consolidateDispensary: currentPathDispensary
      });
    }
  }, []);

  useEffect(() => {
    // MOODIDAY after products load in
    moodiDayScript();
  }, []);

  if (!productLists[defaultMenuType].length) {
    DatalayerAnalytics.pushErrorEvent({
      category: 'expected',
      location: 'shopHomePage',
      description: 'No products found',
      consolidateDispensary: currentPathDispensary
    });
    return (
      <PageState
        header="Products not available."
        link={{
          url: '/locations',
          text: 'Check out our other locations!'
        }}
      />
    );
  }

  return (
    <>
      {process.env.IS_KIOSK !== 'true' ? (
        <section>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(
                shopHomeJsonLD(currentPathDispensary.shopLink)
              )
            }}
          />
        </section>
      ) : null}
      <div className="d-flex flex-column gap-24 margin-bottom-32">
        {showWideBanner && currentPathDispensary.storefrontMap ? (
          <WideBanner
            widebanner={currentPathDispensary.storefrontMap.ctaBanners}
            onClose={() => {
              setShowWideBanner(false);
            }}
          />
        ) : null}
        <PackageComponentWrapper additionalClass="margin-top-32">
          <div className="d-flex flex-column-tablet">
            {!isMobile ? (
              <OverviewCard dispensary={currentPathDispensary} width={width} />
            ) : null}
            <StorefrontBanners
              props={{
                isLoading: false,
                banners: storefrontBanners
              }}
            />
          </div>
        </PackageComponentWrapper>
        {process.env.IS_KIOSK !== 'true' ? <AccountBanner /> : null}
        <div id="categories">
          <StorefrontCarousel
            banners={categories.data}
            isLoading={categories.loading}
            isMobile={isMobile}
            title="Categories"
            type="categories"
          />
        </div>
        <StorefrontCarousel
          banners={favoredBrands.map((fb) => ({
            ...fb,
            url: `${currentPathDispensary.shopLink}/brands/${fb.slug}`
          }))}
          isMobile={isMobile}
          isLoading={false}
          title="Our Brands"
          type="brands"
        />
        {(
          productLists[defaultMenuType] || [
            { title: '', link: '', products: [] }
          ]
        ).map((p) => {
          return (
            <StorefrontCarousel
              key={p.title}
              banners={productsAnalyticsMap(addItemToCart, p.products)}
              isLoading={false}
              isMobile={isMobile}
              link={p.link}
              title={p.title}
              type="products"
            />
          );
        })}
      </div>
    </>
  );
};
