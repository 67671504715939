import moment from 'moment';
import lodash from 'lodash';
import {
  alphanumericSlugify,
  capitalize,
  Consolidate_Product,
  CS_StorefrontBanner2_ImageBlock_CTAAction,
  CS_StorefrontBanner2_TextImageBanner_CTAAction,
  CS_StorefrontBanner2,
  DutchiePlus_ProductFragment,
  slugify,
  CS_Dutchie_Plus_Product_Listing,
  DutchiePlus_PricingType,
  dutchieFriendlyName,
  DutchiePlus_Special,
  ProductTileType,
  CS_DutchiePlus_Menu_Items
} from 'services';
import { StorefrontPageProps } from '../templates/storefrontPage';
import { mandatoryImageMap, optionalImageMap } from './contentstackMap';
import { DispensaryPathWrapperProps } from '../hooks/dispensaryPathContextProvider';
import { UNIT_MAP } from './constants';
import { SpecialTileProps } from '../components/SpecialTile';

/* ------ STOREFRONT BANNERS ------ */
export const storefrontBannersMap = (
  banners: CS_StorefrontBanner2[],
  stateSlug: string,
  dispSlug: string
): StorefrontPageProps['storefrontBanners'] => {
  const mapCtaAction = (
    ctaAction?:
      | CS_StorefrontBanner2_ImageBlock_CTAAction
      | CS_StorefrontBanner2_TextImageBanner_CTAAction
  ): string => {
    if (!ctaAction) {
      return '';
    }
    const shopLink = `/shop/${stateSlug}/${dispSlug}`;

    switch (ctaAction.__typename) {
      case 'StorefrontBanner2BannersImageBlockBlockCtaActionGoToAccount': {
        if (ctaAction.go_to_account?.location) {
          switch (ctaAction.go_to_account.location) {
            case 'Account Page': {
              return '/account';
            }
            case 'Loyalty Page': {
              return '/account/loyalty';
            }
            default: {
              return '';
            }
          }
        }
        return '';
      }
      case 'StorefrontBanner2BannersImageBlockBlockCtaActionGoToBrand': {
        if (ctaAction.go_to_brand?.brand_name) {
          return `${shopLink}/brands/${alphanumericSlugify(ctaAction.go_to_brand?.brand_name)}`;
        }
        return '';
      }
      case 'StorefrontBanner2BannersImageBlockBlockCtaActionGoToBrandCategory': {
        if (
          ctaAction.go_to_brand_category?.brand_name &&
          ctaAction.go_to_brand_category?.category_name
        ) {
          return `${shopLink}/brands/${alphanumericSlugify(
            ctaAction.go_to_brand_category.brand_name
          )}?c=${slugify(ctaAction.go_to_brand_category.category_name)}`;
        }
        return '';
      }
      case 'StorefrontBanner2BannersImageBlockBlockCtaActionGoToCategory': {
        if (ctaAction.go_to_category?.category_name) {
          return `${shopLink}/categories/${slugify(
            ctaAction.go_to_category.category_name
          )}`;
        }
        return '';
      }
      case 'StorefrontBanner2BannersImageBlockBlockCtaActionGoToSearch': {
        if (ctaAction.go_to_search?.search_text) {
          return `${shopLink}/products?k=${ctaAction.go_to_search.search_text}`;
        }
        return '';
      }
      case 'StorefrontBanner2BannersImageBlockBlockCtaActionGoToUrl': {
        if (ctaAction.go_to_url?.url) {
          return ctaAction.go_to_url.url;
        }
        return '';
      }
      default: {
        return '';
      }
    }
  };

  const flatBanners = banners.length
    ? banners
        .map((banner) => banner.banners)
        .flat()
        .sort((a, b) => {
          if (a && b) {
            const aRank = a.image_block?.rank;
            const bRank = b.image_block?.rank;

            if (aRank && bRank) {
              if (aRank < bRank) return -1;
              if (aRank > bRank) return 1;
            }
          }
          return 0;
        })
    : [];
  return flatBanners.reduce(
    (arr: StorefrontPageProps['storefrontBanners'], mb, i) => {
      // did not add text image block because its not in use
      if (mb?.__typename === 'StorefrontBanner2BannersImageBlock') {
        if (
          mb.image_block.where_to_show === 'Web' ||
          mb.image_block.where_to_show === 'Both'
        ) {
          let isValid = false;
          if (mb.image_block.start_time && mb.image_block.end_time) {
            isValid = moment().isBetween(
              moment(mb.image_block.start_time),
              moment(mb.image_block.end_time)
            );
          } else if (mb.image_block.start_time && !mb.image_block.end_time) {
            isValid = moment().isSameOrAfter(mb.image_block.start_time);
          }

          if (isValid) {
            const desktop = mandatoryImageMap(
              mb.image_block.desktop_imageConnection
            );
            // Banners **SHOULD** contain alternative text, especially since these images have text.
            // rolling this back at the request of Mike Bibbey, will try and find a new way to encourage better standards
            if (desktop.alternativeText.length) {
              const cta_url = mapCtaAction(mb.image_block.cta_action[0]);
              // filter out any external links in banners for kiosk
              if (
                (process.env.IS_KIOSK === 'true' &&
                  cta_url.startsWith('/shop')) ||
                process.env.IS_KIOSK !== 'true'
              ) {
                arr.push({
                  cta_url,
                  cta_mode:
                    mb.image_block.cta_action?.length &&
                    mb.image_block.cta_action[0]?.__typename ===
                      'StorefrontBanner2BannersImageBlockBlockCtaActionOpenStoreDrawer'
                      ? mb.image_block.cta_action[0].open_store_drawer?.mode
                      : '',
                  desktop,
                  id: i,
                  mobile: optionalImageMap(
                    mb.image_block.mobile_imageConnection
                  )
                });
              }
            }
          }
        }
      }
      return arr;
    },
    []
  );
};

/* ------ PRODUCT ------ */
const getDisplayName = (str: string) => {
  return str
    .split('_')
    .map((str) => capitalize(str))
    .join(' ');
};

const getSpecialOfferIdsForProduct = (
  variants: DutchiePlus_ProductFragment['variants']
) => {
  const specialOfferIds: string[] = [];
  if (variants) {
    variants.forEach((v) => {
      if (v.specialConditionIds) {
        v.specialConditionIds.forEach((sci) => {
          if (!specialOfferIds.includes(sci)) {
            specialOfferIds.push(sci);
          }
        });
      }
      if (v.specialRewardIds) {
        v.specialRewardIds.forEach((sci) => {
          if (!specialOfferIds.includes(sci)) {
            specialOfferIds.push(sci);
          }
        });
      }
    });
  }
  return specialOfferIds;
};

const thcUnitMap = (unit: string) => {
  switch (unit) {
    case 'mg':
      return 'MILLIGRAMS';
    case 'mg/g':
      return 'MILLIGRAMS_PER_GRAM';
    case 'mg/ml':
      return 'MILLIGRAMS_PER_ML';
    case '%':
      return 'PERCENTAGE';
    default:
      return undefined;
  }
};

const variantsMap = (
  menuType: 'MEDICAL' | 'RECREATIONAL',
  variants: DutchiePlus_ProductFragment['variants']
) => {
  const key = menuType === 'RECREATIONAL' ? 'Rec' : 'Med';

  return variants.map((v) => {
    const price = v[`price${key}`];
    const specialPrice = v[`specialPrice${key}`] || 0;
    const isSpecial = !!(specialPrice && price != specialPrice);

    return {
      flowerEquivalent: v.flowerEquivalent,
      id: v.id,
      isSpecial,
      option: v.option,
      price,
      specialPrice,
      percentOff: isSpecial
        ? 100 - Math.trunc((specialPrice / (price || specialPrice)) * 100)
        : 0,
      quantity: v.quantity,
      specialConditionIds: v.specialConditionIds,
      specialRewardIds: v.specialRewardIds
    };
  });
};

export const productMap = (
  product: DutchiePlus_ProductFragment,
  offerList: DispensaryPathWrapperProps['offerList'],
  menuType: 'MEDICAL' | 'RECREATIONAL'
): Consolidate_Product => {
  const cardFeatures: string[] = [];
  const isConnecticutDispenasry =
    process.env.CONTENTSTACK_ENVIRONMENT?.includes('ct') || false;

  const cannaTemp = product.cannabinoids?.slice() || [];
  if (
    product.potencyThc &&
    product.potencyThc.range.length &&
    product.potencyThc.range[0] &&
    product.potencyThc.unit &&
    thcUnitMap(product.potencyThc.unit)
  ) {
    cannaTemp.push({
      cannabinoid: {
        id: '0',
        name: isConnecticutDispenasry ? 'Total THC' : 'THC'
      },
      value: product.potencyThc.range.sort((a, b) => a - b)[0],
      unit: thcUnitMap(product.potencyThc.unit)
    });
  }

  if (cannaTemp.length) {
    cannaTemp
      .filter((can) => can?.value && can.value > 0.99)
      ?.sort((a, b) => {
        if (a?.value && b?.value) {
          if (a.value > b.value) {
            return -1;
          }
          if (a.value < b.value) {
            return 1;
          }
        }
        return 0;
      })
      .slice(0, 2)
      .forEach((cb) => {
        if (cb?.unit) {
          const cannabinoidName =
            cb?.cannabinoid?.name === 'Total THC'
              ? cb?.cannabinoid?.name
              : cb?.cannabinoid?.name.split(' ')[0];

          let cbValue = `${cb.value}${UNIT_MAP[cb.unit]} ${cannabinoidName}`;
          if (
            product.potencyThc?.range &&
            product.potencyThc.range.length > 1
          ) {
            cbValue = `${Math.floor(Math.min(...product.potencyThc.range))}${
              UNIT_MAP[cb.unit]
            } - ${Math.floor(Math.max(...product.potencyThc.range))}${
              UNIT_MAP[cb.unit]
            } ${cannabinoidName}`;
          }
          cardFeatures.push(cbValue);
        } else {
          return null;
        }
      });
  }

  const specialOfferIds: string[] = getSpecialOfferIdsForProduct(
    product.variants
  );

  const mappedProduct = {
    ...product,
    images: product.images || [],
    menuTypes: product.menuTypes || [],
    tags: product.tags || [],
    __typename: 'ConsolidateProduct',
    brand: product.brand
      ? {
          name: product.brand.name,
          slug: alphanumericSlugify(product.brand?.name) || '',
          description: product.brand.description || '',
          imageUrl: product.brand.imageUrl || undefined,
          id: product.brand.id || ''
        }
      : null,
    batchId: product.posMetaData?.batchName ?? '',
    category:
      product.subcategory === 'DRINKS' && product.category !== 'LIQUIDS'
        ? product.subcategory
        : product.category,
    subcategory:
      product.subcategory === 'DRINKS' ? undefined : product.subcategory,
    cardDescription: cardFeatures.length ? cardFeatures.join(' • ') : '',
    effects: product.effects.map((e) => ({
      key: e,
      displayName: getDisplayName(e)
    })),
    offers: specialOfferIds.reduce((arr: SpecialTileProps[], s) => {
      const matchingSpecial = offerList.find((ol) => ol.id === s);
      if (matchingSpecial) {
        arr.push(matchingSpecial);
      }
      return arr;
    }, []),
    rankings: {
      favoredBrand: 9999,
      popularity: 9999
    },
    variants: variantsMap(menuType, product.variants)
  };
  delete mappedProduct.posMetaData;
  return mappedProduct as Consolidate_Product;
};

export type ProductTileWithRecommendedType = ProductTileType & {
  recommendationCarouselType: 'all' | 'category' | 'none';
};

export const productTileMap = (
  product: DutchiePlus_ProductFragment,
  menuType: 'MEDICAL' | 'RECREATIONAL',
  favoredBrands: string[],
  popularityIndex: number,
  recommendationCarouselType: 'all' | 'category' | 'none' = 'none',
  offerList?: DutchiePlus_Special[]
): ProductTileWithRecommendedType => {
  const cardFeatures: string[] = [];
  const isConnecticutDispenasry =
    process.env.CONTENTSTACK_ENVIRONMENT?.includes('ct') || false;

  const cannaTemp = product.cannabinoids?.slice() || [];
  if (
    product.potencyThc &&
    product.potencyThc.range.length &&
    product.potencyThc.range[0] &&
    product.potencyThc.unit &&
    thcUnitMap(product.potencyThc.unit)
  ) {
    cannaTemp.push({
      cannabinoid: {
        id: '0',
        name: isConnecticutDispenasry ? 'Total THC' : 'THC'
      },
      value: product.potencyThc.range.sort((a, b) => a - b)[0],
      unit: thcUnitMap(product.potencyThc.unit)
    });
  }

  if (cannaTemp.length) {
    cannaTemp
      .filter((can) => can?.value && can.value > 0.99)
      ?.sort((a, b) => {
        if (a?.value && b?.value) {
          if (a.value > b.value) {
            return -1;
          }
          if (a.value < b.value) {
            return 1;
          }
        }
        return 0;
      })
      .slice(0, 2)
      .forEach((cb) => {
        if (cb?.unit) {
          const cannabinoidName =
            cb?.cannabinoid?.name === 'Total THC'
              ? cb?.cannabinoid?.name
              : cb?.cannabinoid?.name.split(' ')[0];

          let cbValue = `${cb.value}${UNIT_MAP[cb.unit]} ${cannabinoidName}`;
          if (
            product.potencyThc?.range &&
            product.potencyThc.range.length > 1
          ) {
            cbValue = `${Math.floor(Math.min(...product.potencyThc.range))}${
              UNIT_MAP[cb.unit]
            } - ${Math.floor(Math.max(...product.potencyThc.range))}${
              UNIT_MAP[cb.unit]
            } ${cannabinoidName}`;
          }
          cardFeatures.push(cbValue);
        } else {
          return null;
        }
      });
  }

  const getFavoredBrandRanking = (brand: string): number => {
    const matchingBrandInd = favoredBrands.findIndex(
      (fb) => fb.trim().toLowerCase() === brand.trim().toLowerCase()
    );
    return matchingBrandInd >= 0 ? matchingBrandInd : 9999;
  };

  const specialOfferIds: string[] = getSpecialOfferIdsForProduct(
    product.variants
  );

  const mappedProduct: ProductTileWithRecommendedType = {
    brand: product.brand
      ? {
          name: product.brand.name,
          slug: alphanumericSlugify(product.brand?.name) || '',
          description: product.brand.description || '',
          imageUrl: product.brand.imageUrl || undefined,
          id: product.brand.id || ''
        }
      : null,
    cardDescription: cardFeatures.length ? cardFeatures.join(' • ') : '',
    category: product.category,
    id: product.id,
    images: product.images || [],
    name: product.name,
    offers: offerList
      ? specialOfferIds.reduce((arr: SpecialTileProps[], s, i) => {
          const matchingSpecial = offerList.find((ol) => ol.id === s);
          if (matchingSpecial) {
            arr.push({
              isLoading: false,
              title:
                matchingSpecial.menuDisplayConfiguration?.name ||
                matchingSpecial.name,
              id: matchingSpecial.id,
              index: i
            });
          }
          return arr;
        }, [])
      : [],
    potencyThc: product.potencyThc,
    strainType: product.strainType,
    subcategory: product.subcategory,
    rankings: {
      favoredBrand: product.brand?.name
        ? getFavoredBrandRanking(product.brand.name)
        : 9999,
      popularity: popularityIndex
    },
    recommendationCarouselType,
    variants: variantsMap(menuType, product.variants)
  };

  return mappedProduct;
};
/* ------ PRODUCT LISTING ------ */
export type MappedProductList = {
  title: string;
  link: string;
  products: ProductTileType[];
};

function cleanString(str: string) {
  return str.trim().toLowerCase();
}

function cleanArray(strs: string[]) {
  return strs.reduce((acc: string[], str: string) => {
    if (str !== undefined && str !== null) {
      acc.push(cleanString(str));
    }
    return acc;
  }, []);
}

export const productListingMap = (
  list: CS_Dutchie_Plus_Product_Listing[],
  products: DutchiePlus_ProductFragment[],
  menuType: 'RECREATIONAL' | 'MEDICAL',
  offerList?: DutchiePlus_Special[],
  slice?: number
) => {
  const maxCount = slice || 10;
  return list.reduce((arr: MappedProductList[], list) => {
    const getProductsForList = () => {
      switch (list.__typename) {
        case 'StorefrontProductListingDynamicList': {
          const filteredProducts: ProductTileWithRecommendedType[] = [];
          products.map((product, i) => {
            if (filteredProducts.length >= maxCount) {
              return;
            } else {
              const brandMatch = !list.dynamic_list.brands.length
                ? true
                : product.brand?.name
                  ? cleanArray(list.dynamic_list.brands).includes(
                      cleanString(product.brand!.name)
                    )
                  : false;
              const categoryMatch = !list.dynamic_list.categories.length
                ? true
                : product.category
                  ? list.dynamic_list.categories.includes(product.category)
                  : false;
              const nameMatch = !list.dynamic_list.name_includes.length
                ? true
                : product.name
                    .toLowerCase()
                    .includes(list.dynamic_list.name_includes.toLowerCase());
              if (
                filteredProducts.length <= maxCount &&
                brandMatch &&
                categoryMatch &&
                nameMatch
              ) {
                filteredProducts.push(
                  productTileMap(product, menuType, [], i, 'none', offerList)
                );
              }
            }
          });
          return {
            title: list.dynamic_list.title,
            link: '',
            products: filteredProducts
          };
        }
        case 'StorefrontProductListingPosTag': {
          const filteredProducts: ProductTileWithRecommendedType[] = [];
          for (let i = 0; i <= products.length; i++) {
            if (filteredProducts.length >= maxCount) {
              break;
            }
            if (
              filteredProducts.length <= maxCount &&
              products[i]?.tags.includes(list.pos_tag.tag)
            ) {
              filteredProducts.push(
                productTileMap(products[i]!, menuType, [], i, 'none')
              );
              i++;
            }
          }
          return {
            title: list.pos_tag.title,
            link: '',
            products: filteredProducts
          };
        }
        case 'StorefrontProductListingCategory': {
          const filteredProducts: ProductTileWithRecommendedType[] = [];
          for (let i = 0; i <= products.length; i++) {
            if (filteredProducts.length >= maxCount) {
              break;
            }
            if (
              filteredProducts.length <= maxCount &&
              products[i]?.category === list.category.key
            ) {
              filteredProducts.push(
                productTileMap(products[i]!, menuType, [], i, 'none')
              );
              i++;
            }
          }
          return {
            title: list.category.title,
            link: list.category.link ?? '',
            products: filteredProducts
          };
        }
        default: {
          return null;
        }
      }
    };
    const currList = getProductsForList();
    if (currList && currList.products.length) {
      arr.push(currList);
    }
    return arr;
  }, []);
};

export const getCategoryProductLists = (
  products: DutchiePlus_ProductFragment[],
  menuType: DutchiePlus_PricingType,
  shopLink: string,
  menuItems: CS_DutchiePlus_Menu_Items[],
  offerList?: DutchiePlus_Special[]
) => {
  const categoryLists: any = {};
  products.forEach((prod, i) => {
    const mappedProduct = productTileMap(
      prod,
      menuType,
      [],
      i,
      'none',
      offerList
    );
    if (prod.category !== 'NOT_APPLICABLE') {
      const match = menuItems.find(
        (mi) => mi.display_name.toUpperCase() === prod.category
      );
      const category =
        match && match.category_name_override
          ? match.category_name_override.toUpperCase()
          : prod.category;

      if (categoryLists[category]?.length) {
        categoryLists[category].push(mappedProduct);
      } else {
        categoryLists[category] = [mappedProduct];
      }
    }
  });

  const list: MappedProductList[] = [];
  Object.keys(categoryLists).forEach((category) => {
    list.push({
      title: dutchieFriendlyName(category),
      link: `${shopLink}/categories/${slugify(category)}`,
      products: categoryLists[category].slice(0, 10)
    });
  });

  return list;
};

/* ------ PRODUCT RECOMMENDATIONS ------ */
type Consolidate_Product_Recommendations = DutchiePlus_ProductFragment & {
  score: number;
};

export const generateRecommendationCriteria = (
  product: DutchiePlus_ProductFragment,
  categoryRecommends: Set<string>,
  subCategoryRecommends: Set<string>,
  strainRecommends: Set<string>,
  effectRecommends: Set<string>
) => {
  // Push other product information into the relvant sets
  if (product.strainType && product.strainType !== 'NOT_APPLICABLE')
    strainRecommends.add(product.strainType);
  if (product.category && product.category !== 'NOT_APPLICABLE')
    categoryRecommends.add(product.category);
  if (product.subcategory && product.subcategory !== 'DEFAULT')
    subCategoryRecommends.add(product.subcategory);
  if (product.effects && product.effects.length > 0)
    product.effects.forEach((effect) => effectRecommends.add(effect));
};

enum ScoringMap {
  STAFFPICK = 21,
  STRAIN = 13,
  CATEGORY = 8,
  SUBCATEGORY = 5,
  // Effects less specific & more generic so should be lowest score
  EFFECTS = 1
}
const BRAND_SCORE_MULTIPLIER = 1.5;
const NUMBER_OF_RECOMMENDATIONS = 10;

const isCuraBrand = (brandName: string, brandsToFavor: string[]): boolean => {
  const sanitizedBrandName = brandName.toLowerCase().trim();
  if (brandsToFavor.length === 0) return false;
  // Flatten brandsToFavor to just the value of brand_name
  const favoredBrands = brandsToFavor.map((brand) =>
    brand.toLowerCase().trim()
  );

  return favoredBrands.includes(sanitizedBrandName);
};

export const scoreProducts = (
  products: DutchiePlus_ProductFragment[],
  favoredBrands: string[],
  productsToHide: Set<string>,
  categoryRecommends: Set<string>,
  subCategoryRecommends: Set<string>,
  strainRecommends: Set<string>,
  effectRecommends: Set<string>
) => {
  const recommendedProducts: Consolidate_Product_Recommendations[] = [];

  products.forEach((product) => {
    let productScore = 0;
    // Don't recommend products already in the cart
    if (productsToHide.has(product.id)) return;

    if (product.staffPick) productScore += ScoringMap.STAFFPICK;
    if (
      product.strainType &&
      product.strainType !== 'NOT_APPLICABLE' &&
      strainRecommends.has(product.strainType)
    )
      productScore += ScoringMap.STRAIN;
    if (
      product.category &&
      product.category !== 'NOT_APPLICABLE' &&
      categoryRecommends.has(product.category)
    )
      productScore += ScoringMap.CATEGORY;
    if (
      product.subcategory &&
      product.subcategory !== 'DEFAULT' &&
      subCategoryRecommends.has(product.subcategory)
    )
      productScore += ScoringMap.SUBCATEGORY;
    if (
      product.effects &&
      product.effects.length > 0 &&
      product.effects.some((effect) => effectRecommends.has(effect))
    )
      productScore += ScoringMap.EFFECTS;

    // Responsible for titling favor towards Curaleaf products
    if (product.brand && isCuraBrand(product.brand.name, favoredBrands))
      productScore = Math.ceil(productScore * BRAND_SCORE_MULTIPLIER);

    // Find the index to insert the product into the recommended products array
    const indexToInsert =
      lodash.sortedIndexBy<Consolidate_Product_Recommendations>(
        recommendedProducts,
        { ...product, score: productScore },
        'score'
      );

    // Add the product to the recommended products list
    recommendedProducts.splice(indexToInsert, 0, {
      ...product,
      score: productScore
    });
  });

  return recommendedProducts.slice(-NUMBER_OF_RECOMMENDATIONS).reverse();
};
